import { FC } from 'react';

import { t as IconCardBlock_t } from 'api/graphql/IconCardBlock/Gql__IconCardBlock__Decoder__Block.gen';
import FeatureBlockSection from '../../iconCardBlock/FeatureBlockSection';
import HowItWorksSection from '../../iconCardBlock/HowItWorksSection';

type Props = {
  block: IconCardBlock_t;
};

const thematicBlocks = {
  light: (block: IconCardBlock_t) => <HowItWorksSection block={block} />,
  dark: (block: IconCardBlock_t) => <FeatureBlockSection block={block} />,
};

const IconCardBlockSection: FC<Props> = ({ block }) => {
  return thematicBlocks[block.theme](block);
};

export default IconCardBlockSection;
