import { FC } from 'react';
import { css, cx } from '@emotion/css';
import Link from 'next/link';

import {
  tabletSm,
  smallScreenOrMobile,
  tabletOrMobile,
  belowLargeScreen,
} from '@prezzee/ribbon/src/theme/Theme__Responsive.bs.js';
import { make as Button } from '@prezzee/ribbon/src/components/personal/Button/Button.bs.js';

import {
  t as N2ColumnBanner_t,
  group as Group_t,
  group2 as Group2_t,
} from 'api/graphql/HeroBanner/Gql__HeroBanner__N2ColumnBanner__Decoder.gen';

import CmsImage from '../CmsImage';
import {
  sharedStyles,
  getTitleSize,
  isSofiaPro,
  hightlightTitleAsString,
  getDescriptionStyles,
} from './columnBannersShared';
import useSmartLink from 'hooks/useSmartLink';
import { curlyBrackets } from 'utils/Regex';
import { highlightText } from 'common/highlightText';

type Props = {
  isFullScreen: boolean;
  isBorderRadius: boolean;
} & N2ColumnBanner_t;

const style = {
  container: tabletBreakpoint => css`
    display: flex;
    justify-content: space-between;

    @media ${tabletBreakpoint} {
      flex-direction: column;

      & > div:first-child {
        margin: 0 0 16px;
      }
    }

    img {
      width: 100%;
    }
  `,
  rightDescription: css`
    margin-top: 8px;
  `,
  leftGroup: (bgColour, isBorderRadius, isBorderRadiusFullscreen, tabletBreakpoint) => css`
    display: flex;
    position: relative;
    background-color: ${bgColour};
    width: 65.4285714%;
    overflow: hidden;
    ${isBorderRadius ? `border-radius: 8px;` : ''}
    ${isBorderRadiusFullscreen
      ? `border-radius: 0 8px 8px 0;
    @media ${tabletBreakpoint} {
      border-radius: 0;
    }`
      : ''}

    .n2cb-leftContent {
      display: flex;
      flex-flow: column;
    }
  `,
  rightGroup: (bgColour, isBorderRadius, isBorderRadiusFullscreen, tabletBreakpoint) => css`
    display: flex;
    position: relative;
    background-color: ${bgColour};
    width: 33.1428571%;
    overflow: hidden;
    ${isBorderRadius ? `border-radius: 8px;` : ''}
    ${isBorderRadiusFullscreen
      ? `border-radius: 8px 0 0 8px;
    @media ${tabletBreakpoint} {
      border-radius: 0;
    }`
      : ''}

    .n2cb-rightContent {
      display: flex;
      flex-flow: column;
    }
  `,
  groupTablet: (tabletBreakpoint, contentClassname, imgClassname, isCenter) => css`
    @media ${tabletBreakpoint} {
      width: 100%;
      flex-direction: column-reverse;
      align-items: ${isCenter ? 'center' : 'flex-start'};
      text-align: ${isCenter ? 'center' : 'left'};
      padding: 0;

      .${contentClassname} {
        ${isCenter ? `align-items: center;` : ``}
        padding: 16px 0 24px ${isCenter ? '0' : '72px'};
        width: ${isCenter ? '51%' : '60%'};
        position: static;
        transform: none;
      }

      .${imgClassname} {
        width: 100%;
      }
    }

    @media ${smallScreenOrMobile} {
      .${contentClassname} {
        width: 80%;
      }
    }

    @media ${tabletSm} {
      .${contentClassname} {
        padding: 20px;
        width: 100%;
      }
    }
  `,
  groupTabletBg: (tabletBreakpoint, contentClassname, isCenter) => css`
    @media ${tabletBreakpoint} {
      width: 100%;

      .${contentClassname} {
        height: 100%;
        padding: 0 0 0 ${isCenter ? '0' : '72px'};
        left: ${isCenter ? '50%' : '0'};
        transform: ${isCenter ? 'translateX(-50%)' : 'none'};
        width: ${isCenter ? '51%' : '60%'};
        justify-content: center;
      }
    }

    @media ${smallScreenOrMobile} {
      .${contentClassname} {
        width: 80%;
      }
    }

    @media ${tabletSm} {
      .${contentClassname} {
        padding: 20px;
        width: 100%;
      }
    }
  `,
  rightGroupDesktop: isCenter => css`
    .n2cb-rightContent {
      position: absolute;
      top: 0;
      left: 50%;
      bottom: 0;
      width: 86%;
      transform: translateX(-50%);
      z-index: 3;
      align-items: ${isCenter ? 'center' : 'flex-start'};
      justify-content: center;
      text-align: ${isCenter ? 'center' : 'left'};
    }

    .n2cb-rightImg {
      width: 100%;
    }
  `,
  leftGroupLeft: isFullScreen => css`
    display: flex;
    align-items: center;
    justify-content: space-between;

    .n2cb-leftContent {
      width: 51%;
      padding: 0 0 0 72px;
      ${isFullScreen
        ? `@media (min-width: 1580px) {
        padding: 0 0 0 calc(((100% / 0.654285714) - 1380px) / 2);
        width: 59%;
      }`
        : ''}
    }

    .n2cb-leftImg {
      width: 39.628821%;
    }
  `,
  leftGroupLeftBg: isFullScreen => css`
    .n2cb-leftContent {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 58%;
      padding: 0 0 0 72px;
      z-index: 3;
      justify-content: center;
      ${isFullScreen
        ? `
      @media (min-width: 1580px) {
        padding: 0 0 0 calc(((100% / 0.654285714) - 1380px) / 2);
        width: 59%;
      }

      @media (min-width: 2000px) {
        width: 64%;
      }
      `
        : ''}
    }

    .n2cb-leftImg {
      width: 100%;
    }

    @media ${belowLargeScreen} {
      .n2cb-leftContent {
        width: 68%;
      }
    }
  `,
  leftGroupCentre: isBg => css`
    padding: ${isBg ? '0' : '0 5.1428571%'};
    text-align: center;

    .n2cb-leftContent {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 48%;
      height: 100%;
      z-index: 3;
      align-items: center;
      justify-content: center;
    }

    .n2cb-leftImg {
      width: 100%;
    }

    @media ${belowLargeScreen} {
      .n2cb-leftContent {
        width: 60%;
      }
    }
  `,
  btnContainer: tabletBreakpoint => css`
    display: flex;
    margin: 24px 0 0;

    div:nth-child(2) {
      margin-left: 12px;
    }

    @media ${tabletBreakpoint} {
      margin: '16px 0 0';
    }
  `,
};

const getTitleClassname = (
  { titleFont, titleFontSize, titleTextColour, highlightTextColour }: Group_t | Group2_t,
  tabletBreakpoint
) =>
  cx(
    sharedStyles.title(titleTextColour, titleFont, highlightTextColour, tabletBreakpoint),
    getTitleSize(titleFontSize)
  );

const getLeftGroupStyle = (isLeftStyle: boolean, isBackground: boolean, isFullScreen) => {
  return isLeftStyle
    ? isBackground
      ? style.leftGroupLeftBg(isFullScreen)
      : style.leftGroupLeft(isFullScreen)
    : style.leftGroupCentre(isBackground);
};

const getImageSizes = (isFullscreen: boolean, isLeftGroup: boolean, isBgImage: boolean, tabletBreakpoint: string) => {
  const left = `${tabletBreakpoint} 100vw, (max-width: 1440px) 24vw, 350px`;
  const leftBg = `${tabletBreakpoint} 100vw, (max-width: 1440px) 62vw, 900px`;
  const leftFull = `${tabletBreakpoint} 100vw, 25vw`;
  const leftBgFull = `${tabletBreakpoint} 100vw, 65vw`;
  const right = `${tabletBreakpoint} 100vw, (max-width: 1440px) 32vw, 462px`;
  const rightFull = `${tabletBreakpoint} 100vw, 33vw`;

  let size = `${+isLeftGroup}${+isBgImage}${+isFullscreen}`;

  return {
    '000': left,
    '010': leftBg,
    '001': leftFull,
    '011': leftBgFull,
    '100': right,
    '110': right,
    '101': rightFull,
    '111': rightFull,
  }[size];
};

const N2ColumnBanner: FC<Props> = ({ smartLink, isFullScreen, isBorderRadius, leftGroup, rightGroup }) => {
  const tabletBreakpoint = isFullScreen ? tabletOrMobile : belowLargeScreen;
  const spotlightProps = useSmartLink(smartLink);

  const isLeftCenter = leftGroup.copyBlockAlignment === 'centre';
  const isLeftBg = leftGroup.imageTreatment === 'background';
  const isRightCenter = rightGroup.copyBlockAlignment === 'centre';
  const isRightBg = rightGroup.imageTreatment === 'background';
  const isHighlightLeft = curlyBrackets.test(leftGroup.title) && isSofiaPro(leftGroup.titleFont);
  const isHighlightRight = curlyBrackets.test(rightGroup.title) && isSofiaPro(rightGroup.titleFont);
  const isRoundedCorners = isBorderRadius && !isFullScreen;
  const isRoundedCornersFullscreen = isBorderRadius && isFullScreen;

  const leftGroupStyle = getLeftGroupStyle(!isLeftCenter, isLeftBg, isFullScreen);
  const leftGroupTablet = isLeftBg
    ? style.groupTabletBg(tabletBreakpoint, 'n2cb-leftContent', isLeftCenter)
    : style.groupTablet(tabletBreakpoint, 'n2cb-leftContent', 'n2cb-leftImg', isLeftCenter);
  const rightGroupTablet = isRightBg
    ? style.groupTabletBg(tabletBreakpoint, 'n2cb-rightContent', isRightCenter)
    : style.groupTablet(tabletBreakpoint, 'n2cb-rightContent', 'n2cb-rightImg', isRightCenter);
  const rightGroupStyle = style.rightGroupDesktop(isLeftCenter);
  const leftImgSizes = getImageSizes(isFullScreen, true, isLeftBg, tabletBreakpoint);
  const rightImgSizes = getImageSizes(isFullScreen, false, isRightBg, tabletBreakpoint);
  const leftDescriptionSizes = getDescriptionStyles(leftGroup.descriptionFontSize);

  return (
    <div className={style.container(tabletBreakpoint)} {...spotlightProps}>
      <div
        className={cx(
          style.leftGroup(leftGroup.bgColour, isRoundedCorners, isRoundedCornersFullscreen, tabletBreakpoint),
          leftGroupStyle,
          leftGroupTablet
        )}
      >
        <div className={'n2cb-leftContent'}>
          <h1
            className={getTitleClassname(leftGroup, tabletBreakpoint)}
            dangerouslySetInnerHTML={{
              __html: isHighlightLeft ? hightlightTitleAsString(leftGroup.title) : leftGroup.title,
            }}
          />
          <p
            className={leftDescriptionSizes(leftGroup.descriptionTextColour, tabletBreakpoint)}
            dangerouslySetInnerHTML={{ __html: leftGroup.description }}
          />
          <div className={style.btnContainer(tabletBreakpoint)}>
            {leftGroup.buttons.map(({ cta_text, cta_url, variant, colour, fill, smartLink }) => (
              <Button key={smartLink.codename} variant={variant} colour={colour} fill={fill}>
                <Link href={cta_url} passHref>
                  <a>
                    <button>{cta_text}</button>
                  </a>
                </Link>
              </Button>
            ))}
          </div>
        </div>
        <div className={`n2cb-leftImg`}>
          <CmsImage
            src={leftGroup.imageDesktop.url}
            width={leftGroup.imageDesktop.width}
            height={leftGroup.imageDesktop.height}
            alt={leftGroup.imageDesktop.description}
            sizes={leftImgSizes}
            className={sharedStyles.imageDesktop(tabletBreakpoint)}
            loading={'eager'}
          />
          <CmsImage
            src={leftGroup.imageTablet.url}
            width={leftGroup.imageTablet.width}
            height={leftGroup.imageTablet.height}
            alt={leftGroup.imageTablet.description}
            sizes={leftImgSizes}
            className={sharedStyles.imageTablet(tabletBreakpoint)}
            loading={'eager'}
          />
          <CmsImage
            src={leftGroup.imageMobile.url}
            width={leftGroup.imageMobile.width}
            height={leftGroup.imageMobile.height}
            alt={leftGroup.imageMobile.description}
            sizes={leftImgSizes}
            className={sharedStyles.imageMobile}
            loading={'eager'}
          />
        </div>
      </div>
      <div
        className={cx(
          style.rightGroup(rightGroup.bgColour, isRoundedCorners, isRoundedCornersFullscreen, tabletBreakpoint),
          rightGroupStyle,
          rightGroupTablet
        )}
      >
        <div className={'n2cb-rightContent'}>
          <h2
            className={getTitleClassname(rightGroup, tabletBreakpoint)}
            dangerouslySetInnerHTML={{
              __html: isHighlightRight ? hightlightTitleAsString(rightGroup.title) : rightGroup.title,
            }}
          />
          <p
            className={sharedStyles.description(rightGroup.descriptionTextColour, tabletBreakpoint)}
            dangerouslySetInnerHTML={{ __html: rightGroup.description }}
          />
          <div className={style.btnContainer(tabletBreakpoint)}>
            {rightGroup.buttons.map(({ cta_text, cta_url, variant, colour, fill, smartLink }) => (
              <Button key={smartLink.codename} variant={variant} colour={colour} fill={fill}>
                <Link href={cta_url} passHref>
                  <a>
                    <button>{cta_text}</button>
                  </a>
                </Link>
              </Button>
            ))}
          </div>
        </div>
        <div className={`n2cb-rightImg`}>
          <CmsImage
            src={rightGroup.imageDesktop.url}
            width={rightGroup.imageDesktop.width}
            height={rightGroup.imageDesktop.height}
            alt={rightGroup.imageDesktop.description}
            sizes={rightImgSizes}
            className={sharedStyles.imageDesktop(tabletBreakpoint)}
            loading={'eager'}
          />
          <CmsImage
            src={rightGroup.imageTablet.url}
            width={rightGroup.imageTablet.width}
            height={rightGroup.imageTablet.height}
            alt={rightGroup.imageTablet.description}
            sizes={rightImgSizes}
            className={sharedStyles.imageTablet(tabletBreakpoint)}
            loading={'eager'}
          />
          <CmsImage
            src={rightGroup.imageMobile.url}
            width={rightGroup.imageMobile.width}
            height={rightGroup.imageMobile.height}
            alt={rightGroup.imageMobile.description}
            sizes={rightImgSizes}
            className={sharedStyles.imageMobile}
            loading={'eager'}
          />
        </div>
      </div>
    </div>
  );
};

export default N2ColumnBanner;
