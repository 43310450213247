import { FC } from 'react';
import { css } from '@emotion/css';

import { make as RichText } from '@prezzee/ribbon/src/components/personal/RichText/RichText.gen';
import { make as FeatureBlock } from '@prezzee/ribbon/src/components/business/FeatureBlock/FeatureBlock.gen';
import { make as Title } from '@prezzee/ribbon/src/components/business/FeatureBlock/FeatureBlock__Title.gen';
import { make as Description } from '@prezzee/ribbon/src/components/business/FeatureBlock/FeatureBlock__Description.gen';
import { make as CardContainer } from '@prezzee/ribbon/src/components/business/FeatureBlock/FeatureBlock__CardContainer.gen';
import { make as CardGrid } from '@prezzee/ribbon/src/components/common/IconCard/IconCard__Grid.gen';
import { tabletSm, tablet } from '@prezzee/ribbon/src/theme/Theme__Responsive.bs';
import { make as CardBuilder } from './CardBuilder.bs';
import { t as IconCardBlock_t } from 'api/graphql/IconCardBlock/Gql__IconCardBlock__Decoder__Block.gen';
import { section } from '../pages/index/IndexStyles.bs';
import useSmartLink from 'hooks/useSmartLink';
import { htmlTag } from 'utils/Regex';

const classes = {
  featureBlock: css`
    & > div {
      ${section}
    }
  `,
  title: css`
    h1 {
      font-size: 50px;
      line-height: 63px;

      @media ${tabletSm} {
        font-size: 50px;
        line-height: 40px;
      }
    }

    h2 {
      font-size: 40px;
      line-height: 50px;

      @media ${tabletSm} {
        font-size: 32px;
        line-height: 40px;
      }
    }

    h3 {
      font-size: 36px;
      line-height: 46px;

      @media ${tabletSm} {
        font-size: 32px;
        line-height: 42px;
      }
    }

    h4 {
      font-size: 32px;
      line-height: 42px;

      @media ${tabletSm} {
        font-size: 28px;
        line-height: 38px;
      }
    }
  `,
  description: css`
    width: 100% !important;
    * {
      text-align: center;
      line-height: 40px;
      margin-top: 0;
      margin-bottom: 17px;
      font-weight: 300;

      @media ${tablet} {
        margin-bottom: 24px;
      }
    }
  `,
};

type Props = {
  block: IconCardBlock_t;
};

const FeatureBlockSection: FC<Props> = ({ block }) => {
  const { title, description, icon_cards, smartLink } = block;
  const spotlightProps = useSmartLink(smartLink);
  const isTitle = title.replace(new RegExp(htmlTag, 'g'), '') !== '';
  const isDescription = description.replace(new RegExp(htmlTag, 'g'), '') !== '';

  return (
    <div {...spotlightProps}>
      {/* @ts-ignore */}
      <FeatureBlock classname={classes.featureBlock}>
        {isTitle ? (
          <Title className={classes.title}>
            <RichText>{title}</RichText>
          </Title>
        ) : null}
        {isDescription ? (
          <Description className={classes.description}>
            <RichText>{description}</RichText>
          </Description>
        ) : null}
        <CardContainer>
          <CardGrid amountOfTiles={icon_cards.length} perRowOnTablet={'Two'}>
            <CardBuilder iconCards={icon_cards} />
          </CardGrid>
        </CardContainer>
      </FeatureBlock>
    </div>
  );
};

export default FeatureBlockSection;
