import logger from 'utils/logger';

import React from 'react';
import { GetServerSidePropsContext, PreviewData } from 'next';
import { ParsedUrlQuery } from 'querystring';

import { make as EnrichedTextBlock } from '../components/enrichedTextSection/EnrichedTextSection.bs';
import IconCardBlock from '../components/pages/business/IconCardBlockSection';
import FeatureBlock from '../components/landingPage/FeatureBlock';
import { make as InnovativeWayToGiftBlock } from '../components/pages/business/InnovativeWayToGiftSection.bs';
import CategoryTileBlock from '../components/pages/index/CategoryTilesSection';
import { make as UniqueSellingPointsBlock } from '../components/pages/index/UniqueSellingPointsSection.bs';
import { make as FaqBlock } from '../components/pages/business/FAQSection.bs';
import { make as FullWidthTextBlock } from '../components/pages/index/FullWidthTextSection.bs';
import { make as GiftExperienceBlock } from '../components/pages/business/GiftExperienceSection.bs';
import { make as DownloadAppsBlock } from '../components/pages/index/DownloadAppsSection.bs';
import { make as NewsletterBlock } from '../components/pages/index/NewsletterSection.bs';
import { make as HeroTileBlock } from '../components/pages/index/HeroTileSection.bs';
import { make as PromoTileBlock } from '../components/pages/index/PromoSection.bs';
import { make as StatsTilesBlock } from '../components/pages/index/StatsTilesSection.bs';
import { make as VideoTextPromoBlock } from '../components/pages/index/VideoTextPromoSection.bs';
import { make as TeamMembersBlock } from '../components/pages/about/MeetTheTeam.bs';
import { make as BusinessFullWidthHeroBannerBlock } from '../components/pages/business/BusinessFullWidthHeroSection.bs';
import { make as FullWidthHeroBannerBlock } from '../components/landingPage/LandingPage__FullWidthHero.bs';
import { make as AutomateSimplifyBlock } from '../components/pages/business/AutomateSimplifySection.bs';
import { make as TestimonialCarouselBlock } from '../components/pages/business/TestimonialCarouselSection.bs';
import { make as BusinessContactFormBlock } from '../components/pages/business/BusinessContactFormSection.bs';
import { make as OffsetTilesBlock } from '../components/pages/business/WeveGotALotToBeProudAbout.bs';
import { make as TrustpilotWidgetBlock } from '../components/TrustpilotWidgetSection.bs';
import { make as BoardOfDirectorsBlock } from '../components/pages/about/BoardOfDirectorsSection.bs';
import { make as ShopBySkuBlock } from '../components/pages/index/ShopBySkuSection.bs';
import { NarrowBannerFull } from '../components/pages/basicContent/NarrowBannerFull';
import SeoMetaBlock from 'components/library/SeoMeta';
import ProductHeroSection from 'components/library/ProductHeroSection';
import SellingPointsSection from 'components/library/SellingPointsSection';
import HeroBannerBlock from 'components/heroBanner/HeroBannerBlock';

import { getPageContent } from '../api/graphql/CmsData.bs';
import { modifyBlocks } from '../api/queries/modifyBlocks';

import { EnvUtil } from './util.bs';
import { Nullable } from 'models/types';
import { SeoMeta } from 'models/Models';
import { EMPTY_ELEMENT } from './utils/react';
import NarrowCTASection from 'components/library/NarrowCtaSection';
import { NO_CACHE, SSR_PAGE_CACHE } from './constants';

/**
 * CMS Content model block value
 */
export type ContentBlock = {
  NAME: ContentBlockName;
  VAL: any;
};

/**
 * The Content block name definition list
 */
export enum ContentBlockName {
  EnrichedTextBlock = 'EnrichedTextBlock',
  IconCardBlock = 'IconCardBlock',
  InnovativeWayToGiftBlock = 'InnovativeWayToGiftBlock',
  CategoryTileBlock = 'CategoryTileBlock',
  GiftExperienceBlock = 'GiftExperienceBlock',
  UniqueSellingPointsBlock = 'UniqueSellingPointsBlock',
  FAQBlock = 'FAQBlock',
  FullWidthTextBlock = 'FullWidthTextBlock',
  NarrowBannerFull = 'NarrowBannerFull',
  DownloadAppsBlock = 'DownloadAppsBlock',
  NewsletterBlock = 'NewsletterBlock',
  HeroTileBlock = 'HeroTileBlock',
  PromoTileBlock = 'PromoTileBlock',
  StatsTilesBlock = 'StatsTilesBlock',
  VideoTextPromoBlock = 'VideoTextPromoBlock',
  TeamMembersBlock = 'TeamMembersBlock',
  FullWidthHeroBannerBlock = 'FullWidthHeroBannerBlock',
  AutomateSimplifyBlock = 'AutomateSimplifyBlock',
  TestimonialCarouselBlock = 'TestimonialCarouselBlock',
  BusinessContactFormBlock = 'BusinessContactFormBlock',
  OffsetTilesBlock = 'OffsetTilesBlock',
  TrustpilotWidgetBlock = 'TrustpilotWidgetBlock',
  SkuCarouselBlock = 'SkuCarouselBlock',
  BoardOfDirectorsBlock = 'BoardOfDirectorsBlock',
  ProductHeroBlock = 'ProductHeroBlock',
  SellingPointsBlock = 'SellingPointsBlock',
  NarrowCta = 'NarrowCta',
  HeroBanner = 'HeroBanner',
}

/**
 * Variants of Content Layout
 */
export enum ContentLayout {
  Classic = 'classic',
  Business = 'business',
}

/**
 * Extra props for The Basic Content block
 */
export type ExtraProps = {
  [blockName: string]: {
    props: any;
  };
};

/**
 * The Basic Content mapper value
 */
type BasicContentValue = {
  [blockName: string]: (
    layout: ContentLayout,
    extraProps?: ExtraProps
  ) => (
    block: ContentBlock,
    key?: number,
    flags?: { layerEnabled: boolean }
  ) => React.ReactElement<{ block: ContentBlock; key?: number; flags?: { layerEnabled: boolean } }>;
};

/**
 * Content block component mapper
 */
export const BasicContent: BasicContentValue = {
  [ContentBlockName.EnrichedTextBlock]:
    (layout: ContentLayout) =>
    (block: ContentBlock, key?: number): React.ReactElement<{ block: ContentBlock; key?: number }> =>
      ({
        [ContentLayout.Classic]: <EnrichedTextBlock block={block.VAL} key={key} />,
        [ContentLayout.Business]: <EnrichedTextBlock block={block.VAL} key={key} />,
      }[layout]),

  [ContentBlockName.IconCardBlock]:
    (layout: ContentLayout) =>
    (block: ContentBlock, key?: number): React.ReactElement<{ block: ContentBlock; key?: number }> =>
      ({
        [ContentLayout.Classic]: <IconCardBlock block={block.VAL} key={key} />,
        [ContentLayout.Business]: <IconCardBlock block={block.VAL} key={key} />,
      }[layout]),

  [ContentBlockName.InnovativeWayToGiftBlock]:
    (layout: ContentLayout) =>
    (block: ContentBlock, key?: number): React.ReactElement<{ block: ContentBlock; key?: number }> =>
      ({
        [ContentLayout.Classic]: EMPTY_ELEMENT,
        [ContentLayout.Business]: <InnovativeWayToGiftBlock block={block.VAL} key={key} />,
      }[layout]),

  [ContentBlockName.CategoryTileBlock]:
    (layout: ContentLayout) =>
    (block: ContentBlock, key?: number): React.ReactElement<{ block: ContentBlock; key?: number }> =>
      ({
        [ContentLayout.Classic]: <CategoryTileBlock block={block.VAL} key={key} />,
        [ContentLayout.Business]: <CategoryTileBlock block={block.VAL} key={key} />,
      }[layout]),

  [ContentBlockName.GiftExperienceBlock]:
    (layout: ContentLayout) =>
    (block: ContentBlock, key?: number): React.ReactElement<{ block: ContentBlock; key?: number }> =>
      ({
        [ContentLayout.Classic]: EMPTY_ELEMENT,
        [ContentLayout.Business]: <GiftExperienceBlock block={block.VAL} key={key} />,
      }[layout]),
  [ContentBlockName.UniqueSellingPointsBlock]:
    (layout: ContentLayout) =>
    (block: ContentBlock, key?: number): React.ReactElement<{ block: ContentBlock; key?: number }> =>
      ({
        [ContentLayout.Classic]: <UniqueSellingPointsBlock block={block.VAL} key={key} />,
        [ContentLayout.Business]: <UniqueSellingPointsBlock block={block.VAL} key={key} />,
      }[layout]),

  [ContentBlockName.FAQBlock]:
    (layout: ContentLayout) =>
    (block: ContentBlock, key?: number): React.ReactElement<{ block: ContentBlock; key?: number }> =>
      ({
        [ContentLayout.Classic]: <FaqBlock block={block.VAL} key={key} />,
        [ContentLayout.Business]: <FaqBlock block={block.VAL} key={key} />,
      }[layout]),

  [ContentBlockName.FullWidthTextBlock]:
    (layout: ContentLayout) =>
    (block: ContentBlock, key?: number): React.ReactElement<{ block: ContentBlock; key?: number }> =>
      ({
        [ContentLayout.Classic]: <FullWidthTextBlock block={block.VAL} key={key} />,
        [ContentLayout.Business]: <FullWidthTextBlock block={block.VAL} key={key} />,
      }[layout]),

  [ContentBlockName.NarrowBannerFull]:
    (layout: ContentLayout) =>
    (block: ContentBlock, key?: number): React.ReactElement<{ block: ContentBlock; key?: number }> =>
      ({
        [ContentLayout.Classic]: <NarrowBannerFull block={block.VAL} key={key} />,
        [ContentLayout.Business]: <NarrowBannerFull block={block.VAL} key={key} />,
      }[layout]),

  [ContentBlockName.DownloadAppsBlock]:
    (layout: ContentLayout) =>
    (block: ContentBlock, key?: number): React.ReactElement<{ block: ContentBlock; key?: number }> =>
      ({
        [ContentLayout.Classic]: <DownloadAppsBlock block={block.VAL} key={key} />,
        [ContentLayout.Business]: <DownloadAppsBlock block={block.VAL} key={key} />,
      }[layout]),

  [ContentBlockName.NewsletterBlock]:
    (layout: ContentLayout) =>
    (block: ContentBlock, key?: number): React.ReactElement<{ block: ContentBlock; key?: number }> =>
      ({
        [ContentLayout.Classic]: <NewsletterBlock block={block.VAL} key={key} />,
        [ContentLayout.Business]: <NewsletterBlock block={block.VAL} key={key} store="business" />,
      }[layout]),

  [ContentBlockName.HeroTileBlock]:
    (layout: ContentLayout) =>
    (block: ContentBlock, key?: number): React.ReactElement<{ block: ContentBlock; key?: number }> =>
      ({
        [ContentLayout.Classic]: <HeroTileBlock block={block.VAL} key={key} />,
        [ContentLayout.Business]: <HeroTileBlock block={block.VAL} key={key} />,
      }[layout]),

  [ContentBlockName.PromoTileBlock]:
    (layout: ContentLayout) =>
    (block: ContentBlock, key?: number): React.ReactElement<{ block: ContentBlock; key?: number }> =>
      ({
        [ContentLayout.Classic]: <PromoTileBlock block={block.VAL} key={key} />,
        [ContentLayout.Business]: <PromoTileBlock block={block.VAL} key={key} />,
      }[layout]),

  [ContentBlockName.StatsTilesBlock]:
    (layout: ContentLayout) =>
    (block: ContentBlock, key?: number): React.ReactElement<{ block: ContentBlock; key?: number }> =>
      ({
        [ContentLayout.Classic]: <StatsTilesBlock block={block.VAL} key={key} />,
        [ContentLayout.Business]: <StatsTilesBlock block={block.VAL} key={key} />,
      }[layout]),

  [ContentBlockName.VideoTextPromoBlock]:
    (layout: ContentLayout) =>
    (block: ContentBlock, key?: number): React.ReactElement<{ block: ContentBlock; key?: number }> =>
      ({
        [ContentLayout.Classic]: <VideoTextPromoBlock block={block.VAL} key={key} />,
        [ContentLayout.Business]: <VideoTextPromoBlock block={block.VAL} key={key} />,
      }[layout]),

  [ContentBlockName.TeamMembersBlock]:
    (layout: ContentLayout) =>
    (block: ContentBlock, key?: number): React.ReactElement<{ block: ContentBlock; key?: number }> =>
      ({
        [ContentLayout.Classic]: <TeamMembersBlock block={block.VAL} key={key} />,
        [ContentLayout.Business]: <TeamMembersBlock block={block.VAL} key={key} />,
      }[layout]),

  [ContentBlockName.AutomateSimplifyBlock]:
    (layout: ContentLayout) =>
    (block: ContentBlock, key?: number): React.ReactElement<{ block: ContentBlock; key?: number }> =>
      ({
        [ContentLayout.Classic]: EMPTY_ELEMENT,
        [ContentLayout.Business]: <AutomateSimplifyBlock block={block.VAL} key={key} />,
      }[layout]),

  [ContentBlockName.FullWidthHeroBannerBlock]:
    (layout: ContentLayout) =>
    (block: ContentBlock, key?: number): React.ReactElement<{ block: ContentBlock; key?: number }> =>
      ({
        [ContentLayout.Classic]: <FullWidthHeroBannerBlock block={block.VAL} key={key} />,
        [ContentLayout.Business]: <BusinessFullWidthHeroBannerBlock block={block.VAL} key={key} />,
      }[layout]),

  [ContentBlockName.TestimonialCarouselBlock]:
    (layout: ContentLayout) =>
    (block: ContentBlock, key?: number): React.ReactElement<{ block: ContentBlock; key?: number }> =>
      ({
        [ContentLayout.Classic]: EMPTY_ELEMENT,
        [ContentLayout.Business]: <TestimonialCarouselBlock block={block.VAL} key={key} />,
      }[layout]),

  [ContentBlockName.BusinessContactFormBlock]:
    (layout: ContentLayout) =>
    (block: ContentBlock, key?: number): React.ReactElement<{ block: ContentBlock; key?: number }> =>
      ({
        [ContentLayout.Classic]: EMPTY_ELEMENT,
        [ContentLayout.Business]: <BusinessContactFormBlock block={block.VAL} key={key} />,
      }[layout]),

  [ContentBlockName.OffsetTilesBlock]:
    (layout: ContentLayout) =>
    (block: ContentBlock, key?: number): React.ReactElement<{ block: ContentBlock; key?: number }> =>
      ({
        [ContentLayout.Classic]: EMPTY_ELEMENT,
        [ContentLayout.Business]: <OffsetTilesBlock block={block.VAL} key={key} />,
      }[layout]),

  [ContentBlockName.TrustpilotWidgetBlock]:
    (layout: ContentLayout) =>
    (block: ContentBlock, key?: number): React.ReactElement<{ block: ContentBlock; key?: number }> =>
      ({
        [ContentLayout.Classic]: <TrustpilotWidgetBlock block={block.VAL} key={key} />,
        [ContentLayout.Business]: <TrustpilotWidgetBlock block={block.VAL} key={key} />,
      }[layout]),

  [ContentBlockName.BoardOfDirectorsBlock]:
    (layout: ContentLayout) =>
    (block: ContentBlock, key?: number): React.ReactElement<{ block: ContentBlock; key?: number }> =>
      ({
        [ContentLayout.Classic]: <BoardOfDirectorsBlock block={block.VAL} key={key} />,
        [ContentLayout.Business]: EMPTY_ELEMENT,
      }[layout]),

  [ContentBlockName.SkuCarouselBlock]:
    (layout: ContentLayout, extraProps: { props: any }) =>
    (block: ContentBlock, key?: number): React.ReactElement<{ block: ContentBlock; key?: number }> =>
      ({
        [ContentLayout.Classic]: <ShopBySkuBlock block={block.VAL} key={key} {...extraProps?.props} />,
        [ContentLayout.Business]: <ShopBySkuBlock block={block.VAL} key={key} {...extraProps?.props} />,
      }[layout]),
  [ContentBlockName.ProductHeroBlock]:
    (layout: ContentLayout) =>
    (block: ContentBlock, key?: number): React.ReactElement<{ block: ContentBlock; key?: number }> =>
      ({
        [ContentLayout.Classic]: <ProductHeroSection block={block.VAL} key={key} />,
        [ContentLayout.Business]: EMPTY_ELEMENT,
      }[layout]),
  [ContentBlockName.SellingPointsBlock]:
    (layout: ContentLayout) =>
    (block: ContentBlock, key?: number): React.ReactElement<{ block: ContentBlock; key?: number }> =>
      ({
        [ContentLayout.Classic]: <SellingPointsSection block={block.VAL} key={key} />,
        [ContentLayout.Business]: EMPTY_ELEMENT,
      }[layout]),
  [ContentBlockName.NarrowCta]:
    (layout: ContentLayout) =>
    (block: ContentBlock, key?: number): React.ReactElement<{ block: ContentBlock; key?: number }> =>
      ({
        [ContentLayout.Classic]: <NarrowCTASection block={block.VAL} key={key} />,
        [ContentLayout.Business]: EMPTY_ELEMENT,
      }[layout]),
  [ContentBlockName.HeroBanner]:
    (layout: ContentLayout) =>
    (
      block: ContentBlock,
      key?: number,
      flags?: { layerEnabled: boolean }
    ): React.ReactElement<{ block: ContentBlock; key?: number }> =>
      ({
        [ContentLayout.Classic]: <HeroBannerBlock block={block.VAL} key={key} layerEnabled={flags?.layerEnabled} />,
        [ContentLayout.Business]: <HeroBannerBlock block={block.VAL} key={key} />,
      }[layout]),
};

/**
 * Parses the Page's Seo Meta block
 * @param content
 */
export function parseSeoMetadata(content: string): React.ReactElement {
  const meta: Nullable<SeoMeta> = JSON.parse(content).seo_meta?.VAL;
  return meta ? <SeoMetaBlock meta={meta} /> : EMPTY_ELEMENT;
}

/**
 * Parses the Page Content blocks CMS value
 * @param content
 * @param layout
 * @param extraProps
 */
export function parseBasicPageContent(
  content: string,
  layout = ContentLayout.Classic,
  extraProps?: ExtraProps,
  flags?: { layerEnabled: boolean }
): React.ReactElement[] {
  return content.length > 0
    ? JSON.parse(content).content_blocks.map((block: ContentBlock, key: number) =>
        BasicContent[block.NAME](layout, extraProps && extraProps[block.NAME])(block, key, flags)
      )
    : [EMPTY_ELEMENT];
}

/**
 * Returns the basic page content
 * @param context
 * @param baseRoute
 */
export async function getBasicPageContentServerProps(
  context: GetServerSidePropsContext<ParsedUrlQuery, PreviewData>,
  baseRoute: string,
  isPid?: boolean
) {
  try {
    const language = EnvUtil.getCmsLanguage();
    const isPreview = !!context.query?.preview;
    const slug = isPid ? context.query?.pid : context.query?.slug;
    const slugParam = slug && `${typeof slug === 'string' ? slug : slug.join('/')}/`;
    const url = `/${baseRoute}/${slugParam || ''}`;

    const content = await getPageContent(language, isPreview, url);

    if (!isPreview) {
      context.res.setHeader('Cache-Control', SSR_PAGE_CACHE);
    }

    return {
      props: {
        content: await modifyBlocks(JSON.stringify(content.content), isPreview, language),
      },
    };
  } catch (error) {
    logger.error(
      {
        err: error,
        journey: 'Page Content',
        source: 'CMS',
      },
      `error: Failed to fetch ${baseRoute} page data`
    );
    // 404 Not Found page redirect for non-existing basic content pages
    context.res.setHeader('location', '/404/');
    context.res.setHeader('Cache-Control', NO_CACHE);
    context.res.statusCode = 404;
    context.res.end();

    return {
      redirect: {
        permanent: false,
        destination: '/404',
      },
    };
  }
}
