import { FC, useMemo, useState } from 'react';
import { css, cx } from '@emotion/css';
import Link from 'next/link';
import dynamic from 'next/dynamic';
import Head from 'next/head';

import Typography from '@prezzee/ribbon-ui/lib/components/Typography';
import colors from '@prezzee/ribbon-ui/lib/theme/foundations/colors';
import breakpoints from '@prezzee/ribbon-ui/lib/theme/foundations/breakpoints';
import { tabletSm } from '@prezzee/ribbon/src/theme/Theme__Responsive.bs.js';
import { make as RichText } from '@prezzee/ribbon/src/components/personal/RichText/RichText.gen';

import useSmartLink from 'hooks/useSmartLink';
import { t as heroIconType } from 'api/graphql/ProductHeroIcon/Gql__ProductHeroIcon__Decoder.gen';
import { t as assetType } from 'api/graphql/AssetType.gen';
import CmsImage from 'components/CmsImage';
import { Sku } from 'models/sku.model';
import { Nullable } from 'models/types';
import { Color } from 'css/theme';

import { t as smartLinkType } from './SmartLink.gen';

const RetailerListDialog = dynamic(() => import('components/pages/store/RetailerListDialog'));

const style = {
  container: css`
    max-width: 1208px;
    margin: 0 auto;
    padding: 65px 32px 56px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    @media (max-width: ${breakpoints.lg}) {
      padding: 23px 32px 48px;
    }

    @media ${tabletSm} {
      flex-direction: column-reverse;
      padding: 16px 0 19px;
    }
  `,
  content: css`
    width: 50%;

    @media ${tabletSm} {
      width: 100%;
      padding: 0 20px 0;
    }
  `,
  subtitle: css`
    margin: 11px 0 0;

    @media (max-width: ${breakpoints.lg}) {
      margin: 8px 0 0;
    }
  `,
  text: css`
    font-size: 16px;
    color: ${colors.gray[600]};
    margin: 8px 0 0;

    ul {
      list-style: disc;
      padding-left: 28px;
      margin-top: 12px;
    }

    a {
      text-decoration: underline;
      color: ${Color.Blue500};
    }

    @media (max-width: ${breakpoints.lg}) {
      margin: 10px 0 0;
    }
  `,
  iconsContainer: css`
    display: flex;
    margin: 19px 0 0;
    padding: 0 0 7px;
  `,
  icon: css`
    width: 33%;
    padding: 6px;
    display: flex;
    flex-flow: column;
    align-items: center;
    text-align: center;
    justify-content: flex-start;
    margin-right: 13px;
    position: relative;
    flex: 1;

    &::after {
      content: '';
      height: 100%;
      width: 1px;
      background: ${colors.gray[300]};
      position: absolute;
      right: -6px;
      top: 7px;
      border-radius: 50%;

      @media ${tabletSm} {
        right: 0;
      }
    }

    &:last-child {
      margin: 0;

      &::after {
        content: none;
      }
    }

    @media ${tabletSm} {
      flex: 1;
      width: 33.3%;
      margin-right: 0;
    }
  `,
  iconImage: css`
    width: 50px;

    @media ${tabletSm} {
      width: 62%;
      max-width: 60px;
    }
  `,
  iconText: css`
    margin: 6px 0 0;

    br {
      &.desktop {
        @media ${breakpoints.lg} {
          display: none;
        }
      }
      &.tablet {
        display: none;
        @media ${breakpoints.lg} {
          display: inline;
        }
        @media ${tabletSm} {
          display: none;
        }
      }
      &.mobile {
        display: none;
        @media ${tabletSm} {
          display: inline;
        }
      }
    }
  `,
  image: css`
    width: 39%;

    @media (max-width: ${breakpoints.lg}) {
      width: 47%;
    }

    @media ${tabletSm} {
      width: 100%;
      margin: 21px 0 0;
    }
  `,
  additionalDescription: css`
    padding-top: 16px;
    margin-top: 24px;
    border-top: 1px solid ${Color.Gray300};

    a {
      text-decoration: underline;
      color: ${Color.Blue500};
    }

    strong {
      display: block;
      font-size: 20px;
      margin-bottom: 8px;
    }

    @media ${tabletSm} {
      margin-top: 20px;
    }
  `,
  btnContainer: css`
    margin-top: 20px;
    display: flex;
    align-items: baseline;
    flex-wrap: wrap;

    @media ${tabletSm} {
      flex-direction: column;
      position: fixed;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 100;
      padding: 12px 20px 6px;
      background: #ffffff;
      box-shadow: 0px -6px 42px rgba(0, 0, 0, 0.17);
      border-radius: 4px;
      align-items: center;
    }
  `,
  primaryBtn: css`
    background: ${colors.red[400]};
    transition: background-color 200ms;
    color: #ffffff;
    font-weight: 600;
    border-radius: 4px;
    height: 50px;
    padding: 0;
    flex-grow: 1;
    white-space: nowrap;
    flex-basis: 48%;

    &:hover {
      background: ${colors.red[500]};
    }

    @media (max-width: ${breakpoints.lg}) {
      height: auto;
      padding: 13px 45px;
    }

    @media ${tabletSm} {
      width: 100%;
      text-align: center;
    }
  `,
  secondaryBtn: css`
    margin: 0 0 0 12px;
    color: ${colors.purple[400]};
    font-weight: 600;
    flex-grow: 1;
    height: 50px;
    white-space: nowrap;
    flex-basis: 48%;

    @media (max-width: ${breakpoints.lg}) {
      margin: 12px 0 0;
      height: auto;
      padding: 13px 45px;
    }

    @media ${tabletSm} {
      width: 100%;
      margin: 7px 0 0;
    }
  `,
};

interface Props {
  block: {
    title: string;
    description: string;
    subtitle: string;
    icons: Nullable<null | undefined | heroIconType>[];
    image: assetType;
    primaryButton: {
      cta_url: string;
      cta_text: string;
    };
    secondaryButton: {
      cta_url: string;
      cta_text: string;
    };
    productSlug: string;
    additionalDescription: string;
    retailerList: Sku[] | null;
    smartLink: smartLinkType;
  };
}

const emptyCMSContent = '<p><br></p>';

const ProductHeroSection: FC<Props> = ({
  block: {
    title,
    subtitle,
    description,
    icons,
    image,
    primaryButton,
    secondaryButton,
    productSlug,
    additionalDescription,
    smartLink,
    retailerList,
  },
}) => {
  const [retailerListOpen, setRetailerListOpen] = useState(false);

  const handleRetailerListClose = () => setRetailerListOpen(false);
  const spotlightProps = useSmartLink(smartLink);

  const iconsContainer = icons.length ? (
    <div className={style.iconsContainer}>
      {icons.map(({ icon: { url, description, width, height }, text, smartLink: { codename } }) => (
        <div className={style.icon} key={codename}>
          <CmsImage
            className={style.iconImage}
            src={url}
            alt={description}
            width={width}
            height={height}
            sizes={'60px'}
          />
          <div className={style.iconText} key={smartLink.itemId} dangerouslySetInnerHTML={{ __html: text }} />
        </div>
      ))}
    </div>
  ) : null;

  return (
    <>
      <Head>
        <style>
          {`@media ${tabletSm} {
            #ada-button-frame {
              bottom: ${secondaryButton ? '114px' : '70px'} !important;
            }
          }`}
        </style>
      </Head>
      <div className={style.container} {...spotlightProps}>
        <CmsImage
          className={style.image}
          src={image.url}
          alt={image.description}
          width={image.width}
          height={image.height}
          sizes={`${tabletSm} 100vw, (max-width: ${breakpoints.lg}) 47vw, 446px`}
        />
        <div className={style.content}>
          <Typography variant={'h1'}>{title}</Typography>
          <Typography className={style.subtitle} variant={'subtitle2'}>
            {subtitle}
          </Typography>
          <RichText className={style.text}>{description}</RichText>
          {iconsContainer}
          <div className={style.btnContainer}>
            <Link href={primaryButton.cta_url} passHref>
              <button className={style.primaryBtn}>
                <a>{primaryButton.cta_text}</a>
              </button>
            </Link>
            {secondaryButton ? (
              <button
                onClick={() => {
                  setRetailerListOpen(true);
                }}
                className={style.secondaryBtn}
              >
                {secondaryButton.cta_text}
              </button>
            ) : null}
          </div>
          {additionalDescription && additionalDescription !== emptyCMSContent ? (
            <RichText className={style.additionalDescription}>{additionalDescription}</RichText>
          ) : null}
        </div>

        {secondaryButton && retailerList?.length > 0 && (
          <RetailerListDialog
            retailerList={retailerList}
            isOpen={retailerListOpen}
            handleRetailerListClose={handleRetailerListClose}
          />
        )}
      </div>
    </>
  );
};

export default ProductHeroSection;
