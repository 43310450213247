// Generated by ReScript, PLEASE EDIT WITH CARE

import * as BsCss from "../../../bindings/BsCss.bs.js";
import * as Curry from "@rescript/std/lib/es6/curry.js";
import * as Segment from "../../../bindings/Segment.bs.js";
import * as CmsImage from "../../CmsImage.bs.js";
import Link from "next/link";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";
import * as IndexStyles from "../index/IndexStyles.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Button$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/Button/Button.bs.js";
import * as Kontent$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/Kontent/Kontent.bs.js";
import * as KontentContextProvider from "../index/KontentContextProvider.bs.js";
import * as RichText$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/RichText/RichText.bs.js";
import * as ReactFontawesome from "@fortawesome/react-fontawesome";
import * as TheGiftExperience$PrezzeeRibbon from "@prezzee/ribbon/src/components/business/TheGiftExperience/TheGiftExperience.bs.js";
import * as Theme__Responsive$PrezzeeRibbon from "@prezzee/ribbon/src/theme/Theme__Responsive.bs.js";
import * as TheGiftExperience__CTA$PrezzeeRibbon from "@prezzee/ribbon/src/components/business/TheGiftExperience/TheGiftExperience__CTA.bs.js";
import * as TheGiftExperience__List$PrezzeeRibbon from "@prezzee/ribbon/src/components/business/TheGiftExperience/TheGiftExperience__List.bs.js";
import * as TheGiftExperience__Heading$PrezzeeRibbon from "@prezzee/ribbon/src/components/business/TheGiftExperience/TheGiftExperience__Heading.bs.js";
import * as TheGiftExperience__ListItem$PrezzeeRibbon from "@prezzee/ribbon/src/components/business/TheGiftExperience/TheGiftExperience__ListItem.bs.js";
import * as TheGiftExperience__ListItemIcon$PrezzeeRibbon from "@prezzee/ribbon/src/components/business/TheGiftExperience/TheGiftExperience__ListItemIcon.bs.js";
import * as TheGiftExperience__ListItemText$PrezzeeRibbon from "@prezzee/ribbon/src/components/business/TheGiftExperience/TheGiftExperience__ListItemText.bs.js";
import * as TheGiftExperience__TextContainer$PrezzeeRibbon from "@prezzee/ribbon/src/components/business/TheGiftExperience/TheGiftExperience__TextContainer.bs.js";
import * as TheGiftExperience__ImageContainer$PrezzeeRibbon from "@prezzee/ribbon/src/components/business/TheGiftExperience/TheGiftExperience__ImageContainer.bs.js";

var uniqueSellingPointWrapper = Curry._1(BsCss.style, {
      hd: BsCss.marginBottom(BsCss.px(26)),
      tl: /* [] */0
    });

var Styles = {
  uniqueSellingPointWrapper: uniqueSellingPointWrapper
};

function segmentTrackBookDemoClick(param) {
  Segment.customElementInteraction({
        element: "book_a_demo_cta",
        journey: "pb_home_page"
      });
}

function GiftExperienceSection(props) {
  var block = props.block;
  var smartLink = block.smartLink;
  var heading = JsxRuntime.jsx(RichText$PrezzeeRibbon.make, {
        children: block.title
      });
  var image = Belt_Option.mapWithDefault(block.promo_image, null, (function (param) {
          return JsxRuntime.jsx(CmsImage.make, {
                      src: param.url,
                      alt: param.description,
                      width: param.width,
                      height: param.height,
                      sizes: "" + Theme__Responsive$PrezzeeRibbon.tabletSm + " 100vw, " + Theme__Responsive$PrezzeeRibbon.tabletSmUp + " 50vw, $(desktop) 544px"
                    });
        }));
  var theGiftExperienceItems = Belt_Array.mapWithIndex(block.list_items, (function (i, item) {
          var listItemSmartLink = item.smartLink;
          var match = item.icon;
          var listIcon = match !== undefined ? JsxRuntime.jsx(ReactFontawesome.FontAwesomeIcon, {
                  icon: [
                    match.style,
                    match.icon
                  ]
                }) : null;
          var descriptionText = JsxRuntime.jsx(RichText$PrezzeeRibbon.make, {
                children: item.description
              });
          return JsxRuntime.jsx(KontentContextProvider.make, {
                      children: JsxRuntime.jsx(Kontent$PrezzeeRibbon.Consumer.make, {
                            children: JsxRuntime.jsx("div", {
                                  children: JsxRuntime.jsxs(TheGiftExperience__ListItem$PrezzeeRibbon.make, {
                                        children: [
                                          JsxRuntime.jsx(TheGiftExperience__ListItemIcon$PrezzeeRibbon.make, {
                                                children: listIcon
                                              }),
                                          JsxRuntime.jsx(TheGiftExperience__ListItemText$PrezzeeRibbon.make, {
                                                children: JsxRuntime.jsx("span", {
                                                      children: descriptionText
                                                    })
                                              })
                                        ]
                                      }),
                                  className: uniqueSellingPointWrapper
                                }, String(i))
                          }),
                      itemId: listItemSmartLink.itemId,
                      elementCodename: listItemSmartLink.codename
                    });
        }));
  var ctaItems = Belt_Array.mapWithIndex(block.buttons, (function (i, item) {
          var target = item.open_in_new_window ? "_blank" : "";
          var match = item.smartLink;
          var cta_text = item.cta_text;
          return JsxRuntime.jsx(KontentContextProvider.make, {
                      children: JsxRuntime.jsx(Kontent$PrezzeeRibbon.Consumer.make, {
                            children: JsxRuntime.jsx("div", {
                                  children: JsxRuntime.jsx(Button$PrezzeeRibbon.make, {
                                        children: JsxRuntime.jsx(Link, {
                                              href: item.cta_url,
                                              prefetch: false,
                                              children: JsxRuntime.jsx("a", {
                                                    children: JsxRuntime.jsx("button", {
                                                          children: cta_text
                                                        }),
                                                    target: target
                                                  })
                                            }),
                                        fill: item.fill,
                                        colour: item.colour,
                                        variant: item.variant
                                      }, String(i)),
                                  onClick: (function (param) {
                                      if (cta_text === "Book a demo") {
                                        return segmentTrackBookDemoClick(undefined);
                                      }
                                      
                                    })
                                })
                          }),
                      itemId: match.itemId,
                      elementCodename: match.codename
                    }, String(i));
        }));
  return JsxRuntime.jsx(KontentContextProvider.make, {
              children: JsxRuntime.jsx(Kontent$PrezzeeRibbon.Consumer.make, {
                    children: JsxRuntime.jsx("div", {
                          children: JsxRuntime.jsxs(TheGiftExperience$PrezzeeRibbon.make, {
                                children: [
                                  JsxRuntime.jsxs(TheGiftExperience__TextContainer$PrezzeeRibbon.make, {
                                        children: [
                                          JsxRuntime.jsx(TheGiftExperience__Heading$PrezzeeRibbon.make, {
                                                children: heading
                                              }),
                                          JsxRuntime.jsx(TheGiftExperience__List$PrezzeeRibbon.make, {
                                                children: theGiftExperienceItems
                                              }),
                                          JsxRuntime.jsx(TheGiftExperience__CTA$PrezzeeRibbon.make, {
                                                children: ctaItems
                                              })
                                        ]
                                      }),
                                  JsxRuntime.jsx(TheGiftExperience__ImageContainer$PrezzeeRibbon.make, {
                                        children: image
                                      })
                                ],
                                classname: IndexStyles.section
                              })
                        })
                  }),
              itemId: smartLink.itemId,
              elementCodename: smartLink.codename
            });
}

var TheGiftExperience;

var Heading;

var List;

var ListItem;

var ListItemIcon;

var ListItemText;

var ImageContainer;

var TextContainer;

var CTALine;

var KontentContextConsumer;

var RichText;

var CardIcon;

var make = GiftExperienceSection;

export {
  TheGiftExperience ,
  Heading ,
  List ,
  ListItem ,
  ListItemIcon ,
  ListItemText ,
  ImageContainer ,
  TextContainer ,
  CTALine ,
  KontentContextConsumer ,
  RichText ,
  CardIcon ,
  Styles ,
  segmentTrackBookDemoClick ,
  make ,
}
/* uniqueSellingPointWrapper Not a pure module */
