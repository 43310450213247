// Generated by ReScript, PLEASE EDIT WITH CARE

import * as BsCss from "../../../bindings/BsCss.bs.js";
import * as Curry from "@rescript/std/lib/es6/curry.js";
import Link from "next/link";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as IndexStyles from "../index/IndexStyles.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Button$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/Button/Button.bs.js";
import * as Kontent$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/Kontent/Kontent.bs.js";
import * as KontentContextProvider from "../index/KontentContextProvider.bs.js";
import * as RichText$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/RichText/RichText.bs.js";
import * as AutomateSimplify$PrezzeeRibbon from "@prezzee/ribbon/src/components/business/AutomateSimplify/AutomateSimplify.bs.js";
import * as AutomateSimplify__Heading$PrezzeeRibbon from "@prezzee/ribbon/src/components/business/AutomateSimplify/AutomateSimplify__Heading.bs.js";
import * as AutomateSimplify__BodyText$PrezzeeRibbon from "@prezzee/ribbon/src/components/business/AutomateSimplify/AutomateSimplify__BodyText.bs.js";
import * as AutomateSimplify__CTAContainer$PrezzeeRibbon from "@prezzee/ribbon/src/components/business/AutomateSimplify/AutomateSimplify__CTAContainer.bs.js";
import * as AutomateSimplify__TextContainer$PrezzeeRibbon from "@prezzee/ribbon/src/components/business/AutomateSimplify/AutomateSimplify__TextContainer.bs.js";

var button = Curry._1(BsCss.style, {
      hd: BsCss.marginLeft(BsCss.px(12)),
      tl: {
        hd: BsCss.marginTop(BsCss.px(12)),
        tl: {
          hd: BsCss.selector("button", {
                hd: BsCss.width(BsCss.px(200)),
                tl: /* [] */0
              }),
          tl: /* [] */0
        }
      }
    });

var ctaWrapper = Curry._1(BsCss.style, {
      hd: BsCss.marginLeft(BsCss.px(-12)),
      tl: {
        hd: BsCss.marginTop(BsCss.px(-12)),
        tl: /* [] */0
      }
    });

var sectionWidth = Curry._1(BsCss.style, {
      hd: BsCss.children(IndexStyles.section_),
      tl: /* [] */0
    });

var Styles = {
  button: button,
  ctaWrapper: ctaWrapper,
  sectionWidth: sectionWidth
};

function AutomateSimplifySection(props) {
  var block = props.block;
  var smartLink = block.smartLink;
  var title = JsxRuntime.jsx(AutomateSimplify__Heading$PrezzeeRibbon.make, {
        children: JsxRuntime.jsx(RichText$PrezzeeRibbon.make, {
              children: block.heading
            })
      });
  var bodyText = JsxRuntime.jsx(AutomateSimplify__BodyText$PrezzeeRibbon.make, {
        children: JsxRuntime.jsx(RichText$PrezzeeRibbon.make, {
              children: block.body_text
            })
      });
  var ctaButtons = Belt_Array.mapWithIndex(block.buttons, (function (index, ctaButton) {
          var smartLink = ctaButton.smartLink;
          var cta_url = ctaButton.cta_url;
          var cta_text = ctaButton.cta_text;
          var target = ctaButton.open_in_new_window ? "_blank" : "";
          var buttonComponent = target === "" ? JsxRuntime.jsx(Link, {
                  href: cta_url,
                  prefetch: false,
                  children: JsxRuntime.jsx("a", {
                        children: JsxRuntime.jsx("button", {
                              children: cta_text
                            }),
                        target: target
                      })
                }) : JsxRuntime.jsx("a", {
                  children: JsxRuntime.jsx("button", {
                        children: cta_text
                      }),
                  href: cta_url,
                  target: target
                });
          return JsxRuntime.jsx(KontentContextProvider.make, {
                      children: JsxRuntime.jsx(Kontent$PrezzeeRibbon.Consumer.make, {
                            children: JsxRuntime.jsx("div", {
                                  children: JsxRuntime.jsx(Button$PrezzeeRibbon.make, {
                                        children: buttonComponent,
                                        colour: /* Red */0,
                                        variant: /* Small */1,
                                        className: button
                                      })
                                })
                          }),
                      itemId: smartLink.itemId,
                      elementCodename: smartLink.codename
                    }, index.toString());
        }));
  return JsxRuntime.jsx(KontentContextProvider.make, {
              children: JsxRuntime.jsx(Kontent$PrezzeeRibbon.Consumer.make, {
                    children: JsxRuntime.jsx("div", {
                          children: JsxRuntime.jsxs(AutomateSimplify$PrezzeeRibbon.make, {
                                children: [
                                  JsxRuntime.jsxs(AutomateSimplify__TextContainer$PrezzeeRibbon.make, {
                                        children: [
                                          title,
                                          bodyText
                                        ]
                                      }),
                                  JsxRuntime.jsx(AutomateSimplify__CTAContainer$PrezzeeRibbon.make, {
                                        children: ctaButtons,
                                        classname: ctaWrapper
                                      })
                                ],
                                classname: sectionWidth
                              })
                        })
                  }),
              itemId: smartLink.itemId,
              elementCodename: smartLink.codename
            });
}

var AutomateSimplify;

var TextContainer;

var Heading;

var CTAContainer;

var BodyText;

var RichText;

var Button;

var KontentContextConsumer;

var make = AutomateSimplifySection;

export {
  AutomateSimplify ,
  TextContainer ,
  Heading ,
  CTAContainer ,
  BodyText ,
  RichText ,
  Button ,
  KontentContextConsumer ,
  Styles ,
  make ,
}
/* button Not a pure module */
