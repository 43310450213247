import { FC } from 'react';
import { css, cx } from '@emotion/css';
import Link from 'next/link';

import useSmartLink from 'hooks/useSmartLink';
import { t as N1ColumnBanner_t } from 'api/graphql/HeroBanner/Gql__HeroBanner__N1ColumnBanner__Decoder.gen';

import {
  belowLargeScreen,
  smallScreenOrMobile,
  tabletOrMobile,
  tabletSm,
} from '@prezzee/ribbon/src/theme/Theme__Responsive.bs.js';
import { make as Button } from '@prezzee/ribbon/src/components/personal/Button/Button.bs.js';

import CmsImage from '../CmsImage';
import {
  sharedStyles,
  getTitleSize,
  isSofiaPro,
  hightlightTitleAsString,
  getDescriptionStyles,
} from './columnBannersShared';
import { curlyBrackets } from 'utils/Regex';

const style = {
  container: (bgColour, isBorderRadius) => css`
    display: flex;
    position: relative;
    background-color: ${bgColour};
    overflow: hidden;
    ${isBorderRadius
      ? `
      border-radius: 8px;
    `
      : ''};

    img {
      width: 100%;
    }
  `,
  styleSide: (tabletBreakpoint, isRight, isFullScreen) => css`
    align-items: center;
    justify-content: space-between;
    flex-direction: ${isRight ? 'row-reverse' : 'row'};

    .n1cb-content {
      padding: ${isRight ? '0 72px 0 0' : '0 0 0 72px'};
      width: 42%;
      ${isFullScreen && !isRight
        ? `
        @media (min-width: 1500px) {
          padding: 0 0 0 calc((100% - 1380px) / 2);
          width: 48%;
        }
      `
        : ''}
    }

    .n1cb-primaryImg {
      width: 44%;
    }

    @media ${tabletBreakpoint} {
      flex-direction: column-reverse;
      align-items: flex-start;

      .n1cb-content {
        padding: 16px 24px 24px;
        width: 60%;
      }

      .n1cb-primaryImg {
        width: 100%;
      }
    }

    @media ${smallScreenOrMobile} {
      .n1cb-content {
        width: 80%;
      }
    }

    @media ${tabletSm} {
      flex-direction: column-reverse;

      .n1cb-content {
        padding: 20px;
        width: 100%;
      }
    }
  `,
  styleSideBg: isRight => css`
    .n1cb-content {
      position: absolute;
      top: 0;
      left: ${isRight ? 'unset' : '0'};
      right: ${isRight ? '0' : 'unset'};
      bottom: 0;
      justify-content: center;
    }

    .n1cb-primaryImg {
      width: 100%;
    }

    @media ${tabletSm} {
      flex-direction: column-reverse;
    }
  `,
  styleCenter: (tabletBreakpoint, isSecondImageMissing) => css`
    flex-direction: row-reverse;
    justify-content: space-between;

    .n1cb-content {
      width: 33%;
      justify-content: center;
      text-align: center;
    }

    .n1cb-primaryImg {
      width: 30%;
    }

    .n1cb-secondaryImg {
      width: 30%;
    }

    .n1cb-btns {
      justify-content: center;
    }

    @media ${tabletBreakpoint} {
      flex-direction: column;
      align-items: center;

      ${isSecondImageMissing ? 'flex-direction: column-reverse;' : ''}

      .n1cb-primaryImg,
      .n1cb-secondaryImg {
        width: 100%;
      }

      .n1cb-content {
        padding: 20px;
        width: 60%;
      }
    }

    @media ${smallScreenOrMobile} {
      .n1cb-content {
        width: 100%;
      }
    }

    @media ${tabletSm} {
      flex-direction: column-reverse;
    }
  `,
  styleCenterBg: css`
    .n1cb-content {
      width: 40%;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }

    .n1cb-primaryImg {
      width: 50%;
    }

    .n1cb-secondaryImg {
      width: 50%;
    }

    .n1cb-btns {
      justify-content: center;
    }

    @media ${belowLargeScreen} {
      .n1cb-primaryImg,
      .n1cb-secondaryImg {
        width: 100%;
      }

      .n1cb-content {
        width: 60%;
      }
    }

    @media ${smallScreenOrMobile} {
      .n1cb-content {
        width: 100%;
      }
    }
  `,
  content: css`
    display: flex;
    flex-flow: column;
    position: relative;
    z-index: 10;
  `,
  description: css`
    margin-top: 12px;
  `,
  btnContainer: css`
    display: flex;
    margin-top: 24px;

    div:nth-child(2) {
      margin-left: 12px;
    }
  `,
  secondaryImageContainer: isSecondImg => css`
    display: ${isSecondImg ? 'block' : 'none'};
  `,
};

type Props = {
  isFullScreen: boolean;
  isBorderRadius: boolean;
} & N1ColumnBanner_t;

const getImageSizes = (isFullscreen: boolean, isBgImage: boolean, isSecondImage: boolean, tabletBreakpoint) => {
  const sizes = `${tabletBreakpoint} 100vw, (max-width: 1440px) 42vw, 610px`;
  const sizesBg = `(max-width: 1440px) 100vw, 1380px`;
  const sizesFull = `${tabletBreakpoint} 100vw, 44vw`;
  const sizesBgFull = `100vw`;
  const center = `${tabletBreakpoint} 100vw, (max-width: 1440px) 28vw, 420px`;
  const centerBg = `${tabletBreakpoint} 100vw, (max-width: 1440px) 50vw, 690px`;
  const centerFull = `${tabletBreakpoint} 100vw, 30vw`;
  const centerBgFull = `${tabletBreakpoint} 100vw, 50vw`;

  let size = `${+isSecondImage}${+isBgImage}${+isFullscreen}`;

  return {
    '000': sizes,
    '010': sizesBg,
    '001': sizesFull,
    '011': sizesBgFull,
    '100': center,
    '110': centerBg,
    '101': centerFull,
    '111': centerBgFull,
  }[size];
};

const N1ColumnBanner: FC<Props> = ({
  smartLink,
  isFullScreen,
  isBorderRadius,
  bgColour,
  copyBlockAlignment,
  imageTreatment,
  title,
  highlightTextColour,
  titleFont,
  titleTextColour,
  titleFontSize,
  description,
  descriptionTextColour,
  descriptionFontSize,
  buttons,
  imageDesktop,
  imageTablet,
  imageMobile,
  imageN2Desktop,
  imageN2Tablet,
  imageN2Mobile,
}) => {
  const isBgImage = imageTreatment === 'background';
  const isSecondImage = copyBlockAlignment === 'centre';
  const titleSize = getTitleSize(titleFontSize);
  const tabletBreakpoint = isFullScreen ? tabletOrMobile : belowLargeScreen;
  const imgSizes = getImageSizes(isFullScreen, isBgImage, isSecondImage, tabletBreakpoint);
  const spotlightProps = useSmartLink(smartLink);
  const isHighlight = curlyBrackets.test(title) && isSofiaPro(titleFont);
  const descriptionStyles = getDescriptionStyles(descriptionFontSize);
  const isSecondImageMissing = isSecondImage && !imageN2Tablet;

  const styleClass = {
    left: isBgImage
      ? cx(style.styleSide(tabletBreakpoint, false, isFullScreen), style.styleSideBg(false))
      : style.styleSide(tabletBreakpoint, false, isFullScreen),
    centre: isBgImage
      ? cx(style.styleCenter(tabletBreakpoint, false), style.styleCenterBg)
      : style.styleCenter(tabletBreakpoint, isSecondImageMissing),
    right: isBgImage
      ? cx(style.styleSide(tabletBreakpoint, true, isFullScreen), style.styleSideBg(true))
      : style.styleSide(tabletBreakpoint, true, isFullScreen),
  }[copyBlockAlignment];

  return (
    <div className={cx(style.container(bgColour, isBorderRadius && !isFullScreen), styleClass)} {...spotlightProps}>
      {isSecondImage && (
        <div className={`n1cb-secondaryImg`}>
          {imageN2Desktop && (
            <CmsImage
              src={imageN2Desktop.url}
              width={imageN2Desktop.width}
              height={imageN2Desktop.height}
              alt={imageN2Desktop.description}
              sizes={imgSizes}
              className={sharedStyles.imageDesktop(tabletBreakpoint)}
              priority={true}
            />
          )}
          {imageN2Tablet && (
            <CmsImage
              src={imageN2Tablet.url}
              width={imageN2Tablet.width}
              height={imageN2Tablet.height}
              alt={imageN2Tablet.description}
              sizes={imgSizes}
              className={sharedStyles.imageTablet(tabletBreakpoint)}
              priority={true}
            />
          )}
          {imageN2Mobile && (
            <CmsImage
              src={imageN2Mobile.url}
              width={imageN2Mobile.width}
              height={imageN2Mobile.height}
              alt={imageN2Mobile.description}
              sizes={imgSizes}
              className={sharedStyles.imageMobile}
              priority={true}
            />
          )}
        </div>
      )}
      <div className={cx(style.content, 'n1cb-content')}>
        <h1
          className={cx(
            sharedStyles.title(titleTextColour, titleFont, highlightTextColour, tabletBreakpoint),
            titleSize
          )}
          dangerouslySetInnerHTML={{ __html: isHighlight ? hightlightTitleAsString(title) : title }}
        />
        <p
          className={cx(descriptionStyles(descriptionTextColour, tabletBreakpoint), style.description)}
          dangerouslySetInnerHTML={{ __html: description }}
        />
        <div className={cx(style.btnContainer, 'n1cb-btns')}>
          {buttons.map(({ cta_text, cta_url, variant, colour, fill, smartLink }) => (
            <Button key={smartLink.codename} variant={variant} colour={colour} fill={fill}>
              <Link href={cta_url} passHref>
                <a>
                  <button>{cta_text}</button>
                </a>
              </Link>
            </Button>
          ))}
        </div>
      </div>
      <div className={`n1cb-primaryImg`}>
        <CmsImage
          src={imageDesktop.url}
          width={imageDesktop.width}
          height={imageDesktop.height}
          alt={imageDesktop.description}
          sizes={imgSizes}
          className={sharedStyles.imageDesktop(tabletBreakpoint)}
          loading={'eager'}
        />
        <CmsImage
          src={imageTablet.url}
          width={imageTablet.width}
          height={imageTablet.height}
          alt={imageTablet.description}
          sizes={imgSizes}
          className={sharedStyles.imageTablet(tabletBreakpoint)}
          loading={'eager'}
        />
        <CmsImage
          src={imageMobile.url}
          width={imageMobile.width}
          height={imageMobile.height}
          alt={imageMobile.description}
          sizes={imgSizes}
          className={sharedStyles.imageMobile}
          loading={'eager'}
        />
      </div>
    </div>
  );
};

export default N1ColumnBanner;
