// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cn from "rescript-classnames/src/Cn.bs.js";
import * as Css from "bs-css-emotion/src/Css.bs.js";
import * as BsCss from "../../../bindings/BsCss.bs.js";
import * as Curry from "@rescript/std/lib/es6/curry.js";
import * as CmsImage from "../../CmsImage.bs.js";
import Link from "next/link";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as IndexStyles from "../index/IndexStyles.bs.js";
import * as React from "swiper/react";
import * as JsxRuntime from "react/jsx-runtime";
import * as ImageCollectionUtil from "./ImageCollectionUtil.bs.js";
import * as Kontent$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/Kontent/Kontent.bs.js";
import * as KontentContextProvider from "../index/KontentContextProvider.bs.js";
import * as RichText$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/RichText/RichText.bs.js";
import * as ImageGrid$PrezzeeRibbon from "@prezzee/ribbon/src/components/common/ImageGrid/ImageGrid.bs.js";
import * as Theme__Color$PrezzeeRibbon from "@prezzee/ribbon/src/theme/Theme__Color.bs.js";
import * as SectionHeading$PrezzeeRibbon from "@prezzee/ribbon/src/components/common/SectionHeading/SectionHeading.bs.js";
import * as DeviceSizeImage$PrezzeeRibbon from "@prezzee/ribbon/src/components/common/DeviceSizeImage/DeviceSizeImage.bs.js";
import * as FullWidthCarousel$PrezzeeRibbon from "@prezzee/ribbon/src/components/common/FullWidthCarousel/FullWidthCarousel.bs.js";
import * as Theme__Responsive$PrezzeeRibbon from "@prezzee/ribbon/src/theme/Theme__Responsive.bs.js";
import * as FullWidthCarousel__Slide$PrezzeeRibbon from "@prezzee/ribbon/src/components/common/FullWidthCarousel/FullWidthCarousel__Slide.bs.js";
import * as FullWidthCarousel__SlideIcon$PrezzeeRibbon from "@prezzee/ribbon/src/components/common/FullWidthCarousel/FullWidthCarousel__SlideIcon.bs.js";
import * as FullWidthCarousel__SlideContent$PrezzeeRibbon from "@prezzee/ribbon/src/components/common/FullWidthCarousel/FullWidthCarousel__SlideContent.bs.js";
import * as SectionHeading__Theme__Business$PrezzeeRibbon from "@prezzee/ribbon/src/components/business/SectionHeading/SectionHeading__Theme__Business.bs.js";
import * as FullWidthCarousel__SlideBackground$PrezzeeRibbon from "@prezzee/ribbon/src/components/common/FullWidthCarousel/FullWidthCarousel__SlideBackground.bs.js";
import * as FullWidthCarousel__SlideFooterText$PrezzeeRibbon from "@prezzee/ribbon/src/components/common/FullWidthCarousel/FullWidthCarousel__SlideFooterText.bs.js";
import * as FullWidthCarousel__SlideDescription$PrezzeeRibbon from "@prezzee/ribbon/src/components/common/FullWidthCarousel/FullWidthCarousel__SlideDescription.bs.js";

var SectionHeading = SectionHeading$PrezzeeRibbon.Component(SectionHeading__Theme__Business$PrezzeeRibbon.Theme);

var wrapper = Curry._1(BsCss.style, {
      hd: BsCss.maxWidth(BsCss.px(1204)),
      tl: {
        hd: BsCss.paddingTop(BsCss.px(72)),
        tl: {
          hd: BsCss.paddingBottom(BsCss.px(72)),
          tl: {
            hd: BsCss.marginLeft("auto"),
            tl: {
              hd: BsCss.marginRight("auto"),
              tl: /* [] */0
            }
          }
        }
      }
    });

var heading = Curry._1(BsCss.style, {
      hd: BsCss.selector("h1, h2, h3, h4, h5, h6", {
            hd: BsCss.fontSize(BsCss.px(32)),
            tl: {
              hd: BsCss.lineHeight(BsCss.px(40)),
              tl: {
                hd: BsCss.textAlign("center"),
                tl: {
                  hd: BsCss.maxWidth(BsCss.px(520)),
                  tl: {
                    hd: BsCss.margin2("zero", "auto"),
                    tl: {
                      hd: BsCss.media(Theme__Responsive$PrezzeeRibbon.desktop, {
                            hd: BsCss.fontSize(BsCss.px(42)),
                            tl: {
                              hd: BsCss.lineHeight(BsCss.px(58)),
                              tl: {
                                hd: BsCss.maxWidth(BsCss.px(790)),
                                tl: /* [] */0
                              }
                            }
                          }),
                      tl: /* [] */0
                    }
                  }
                }
              }
            }
          }),
      tl: /* [] */0
    });

var grid = Curry._1(BsCss.style, {
      hd: BsCss.maxWidth(BsCss.px(970)),
      tl: {
        hd: BsCss.margin2(BsCss.px(32), "auto"),
        tl: {
          hd: BsCss.media(Theme__Responsive$PrezzeeRibbon.desktop, {
                hd: BsCss.margin2(BsCss.px(40), "auto"),
                tl: /* [] */0
              }),
          tl: {
            hd: BsCss.media(Theme__Responsive$PrezzeeRibbon.tabletSm, {
                  hd: BsCss.marginBottom(BsCss.px(30)),
                  tl: /* [] */0
                }),
            tl: /* [] */0
          }
        }
      }
    });

var swiperSlide = Curry._1(BsCss.style, {
      hd: BsCss.important(BsCss.height("auto")),
      tl: /* [] */0
    });

var slide = Curry._1(BsCss.style, {
      hd: BsCss.alignItems("center"),
      tl: {
        hd: BsCss.display("flex"),
        tl: /* [] */0
      }
    });

var slideBg = Curry._1(BsCss.style, {
      hd: BsCss.backgroundColor(Theme__Color$PrezzeeRibbon.Business.stone),
      tl: {
        hd: BsCss.width(BsCss.pct(100)),
        tl: {
          hd: BsCss.display("flex"),
          tl: {
            hd: BsCss.height(BsCss.pct(100)),
            tl: /* [] */0
          }
        }
      }
    });

var slideContent = Curry._1(BsCss.style, {
      hd: BsCss.flex3(0, 0, BsCss.pct(100)),
      tl: {
        hd: BsCss.maxWidth(BsCss.pct(100)),
        tl: /* [] */0
      }
    });

var link = Curry._1(BsCss.style, {
      hd: BsCss.width(BsCss.pct(100)),
      tl: {
        hd: BsCss.display("flex"),
        tl: {
          hd: BsCss.height(BsCss.pct(100)),
          tl: /* [] */0
        }
      }
    });

var carouselContainer = Curry._1(BsCss.style, {
      hd: BsCss.media(Theme__Responsive$PrezzeeRibbon.tabletOrDesktop, {
            hd: BsCss.paddingLeft(BsCss.rem(1.875)),
            tl: {
              hd: BsCss.paddingRight(BsCss.rem(1.875)),
              tl: /* [] */0
            }
          }),
      tl: /* [] */0
    });

function cmsImg(w, h) {
  return Curry._1(BsCss.style, {
              hd: BsCss.width(BsCss.px(w)),
              tl: {
                hd: BsCss.height(BsCss.px(h)),
                tl: /* [] */0
              }
            });
}

var Styles = {
  wrapper: wrapper,
  heading: heading,
  grid: grid,
  swiperSlide: swiperSlide,
  slide: slide,
  slideBg: slideBg,
  slideContent: slideContent,
  link: link,
  carouselContainer: carouselContainer,
  cmsImg: cmsImg
};

function getHeading(heading$1) {
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsx(SectionHeading.make, {
                    children: JsxRuntime.jsx(RichText$PrezzeeRibbon.make, {
                          children: heading$1
                        }),
                    className: heading
                  })
            });
}

function getImage(images) {
  return Belt_Array.mapWithIndex(images, (function (idx, img) {
                var key = String(idx);
                if (img.NAME === "DeviceSizeImage") {
                  var images = img.VAL;
                  var imageD = ImageCollectionUtil.Helpers.getAssetOption(images.desktop_image);
                  var imageT = ImageCollectionUtil.Helpers.getAssetOption(images.tablet_image);
                  var imageM = ImageCollectionUtil.Helpers.getAssetOption(images.mobile_image);
                  return JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx(DeviceSizeImage$PrezzeeRibbon.make, {
                                    desktop: imageD,
                                    smallScreen: undefined,
                                    tablet: imageT,
                                    mobile: imageM
                                  })
                            }, key);
                }
                var img$1 = img.VAL;
                return JsxRuntime.jsx("div", {
                            children: JsxRuntime.jsx("img", {
                                  alt: img$1.description,
                                  height: String(img$1.height),
                                  src: img$1.url,
                                  width: String(img$1.width)
                                })
                          }, key);
              }));
}

function getImageGrid(testimonial_image_grid) {
  return JsxRuntime.jsx(ImageGrid$PrezzeeRibbon.make, {
              children: ImageCollectionUtil.Helpers.getImages(testimonial_image_grid.images),
              className: grid,
              gap: testimonial_image_grid.gap_desktop,
              gapTablet: testimonial_image_grid.gap_tablet,
              gapMobile: testimonial_image_grid.gap_mobile,
              perRow: testimonial_image_grid.display_per_row_desktop,
              perRowTablet: testimonial_image_grid.display_per_row_tablet,
              perRowMobile: testimonial_image_grid.display_per_row_mobile
            });
}

function getCarousel(testimonial_carousel_items) {
  var carouselItems = Belt_Array.mapWithIndex(testimonial_carousel_items, (function (idx, item) {
          var smartLink = item.smartLink;
          var url = item.url;
          var image = item.image;
          var key = String(idx);
          var bgD = ImageCollectionUtil.Helpers.getImageOption(item.background_image);
          var bgS = ImageCollectionUtil.Helpers.getImageOption(item.background_image_tablet);
          var bgM = ImageCollectionUtil.Helpers.getImageOption(item.background_image_mobile);
          var bgColourStyle = Belt_Option.mapWithDefault(item.background_colour, "", (function (bg) {
                  return Curry._1(Css.style, {
                              hd: Css.unsafe("backgroundColor", bg),
                              tl: /* [] */0
                            });
                }));
          var logo;
          if (image !== undefined) {
            var height = image.height;
            var width = image.width;
            logo = JsxRuntime.jsx(FullWidthCarousel__SlideIcon$PrezzeeRibbon.make, {
                  children: JsxRuntime.jsx(CmsImage.make, {
                        src: image.url,
                        alt: image.description,
                        width: width,
                        height: height,
                        className: cmsImg(width, height),
                        sizes: "" + String(width) + "px"
                      })
                });
          } else {
            logo = null;
          }
          var slideContent$1 = JsxRuntime.jsx(KontentContextProvider.make, {
                children: JsxRuntime.jsx(Kontent$PrezzeeRibbon.Consumer.make, {
                      children: JsxRuntime.jsx("div", {
                            children: JsxRuntime.jsxs(FullWidthCarousel__Slide$PrezzeeRibbon.make, {
                                  children: [
                                    JsxRuntime.jsx(FullWidthCarousel__SlideBackground$PrezzeeRibbon.make, {
                                          children: JsxRuntime.jsx(DeviceSizeImage$PrezzeeRibbon.make, {
                                                desktop: bgD,
                                                smallScreen: bgS,
                                                tablet: undefined,
                                                mobile: bgM
                                              })
                                        }),
                                    JsxRuntime.jsxs(FullWidthCarousel__SlideContent$PrezzeeRibbon.make, {
                                          children: [
                                            logo,
                                            JsxRuntime.jsx(FullWidthCarousel__SlideDescription$PrezzeeRibbon.make, {
                                                  children: item.quote
                                                }),
                                            JsxRuntime.jsx(FullWidthCarousel__SlideFooterText$PrezzeeRibbon.make, {
                                                  children: JsxRuntime.jsx(RichText$PrezzeeRibbon.make, {
                                                        children: item.author
                                                      })
                                                })
                                          ],
                                          className: slideContent
                                        })
                                  ],
                                  className: slide
                                }),
                            className: Cn.make([
                                  slideBg,
                                  bgColourStyle
                                ])
                          })
                    }),
                itemId: smartLink.itemId,
                elementCodename: smartLink.codename
              });
          var slide$1;
          if (url !== undefined) {
            var href = url.url;
            slide$1 = url.open_in_new_window ? JsxRuntime.jsx("a", {
                    children: slideContent$1,
                    className: link,
                    href: href,
                    target: "_blank"
                  }) : JsxRuntime.jsx(Link, {
                    href: href,
                    prefetch: false,
                    children: JsxRuntime.jsx("a", {
                          children: slideContent$1,
                          className: link
                        })
                  });
          } else {
            slide$1 = JsxRuntime.jsx(JsxRuntime.Fragment, {
                  children: Caml_option.some(slideContent$1)
                });
          }
          return JsxRuntime.jsx(React.SwiperSlide, {
                      className: swiperSlide,
                      children: slide$1
                    }, key);
        }));
  return JsxRuntime.jsx(FullWidthCarousel$PrezzeeRibbon.make, {
              children: carouselItems,
              showPagination: true,
              autoplay: true
            });
}

var Builder = {
  $$Image: undefined,
  getHeading: getHeading,
  getImage: getImage,
  getImageGrid: getImageGrid,
  getCarousel: getCarousel
};

function TestimonialCarouselSection(props) {
  var block = props.block;
  var smartLink = block.smartLink;
  var testimonial_image_grid = block.testimonial_image_grid;
  var imageGrid = testimonial_image_grid !== undefined ? getImageGrid(testimonial_image_grid) : null;
  var carousel = getCarousel(block.testimonial_carousel_items);
  return JsxRuntime.jsx(KontentContextProvider.make, {
              children: JsxRuntime.jsx(Kontent$PrezzeeRibbon.Consumer.make, {
                    children: JsxRuntime.jsxs("div", {
                          children: [
                            JsxRuntime.jsxs("div", {
                                  children: [
                                    getHeading(block.title),
                                    imageGrid
                                  ],
                                  className: IndexStyles.section
                                }),
                            JsxRuntime.jsx("div", {
                                  children: carousel,
                                  className: carouselContainer
                                })
                          ],
                          className: wrapper
                        })
                  }),
              itemId: smartLink.itemId,
              elementCodename: smartLink.codename
            });
}

var KontentContextConsumer;

var RichText;

var ImageGrid;

var Carousel;

var Slide;

var SlideBg;

var SlideContent;

var SlideIcon;

var SlideDescription;

var SlideFooter;

var DeviceSizeImage;

var Link$1;

var ImageCollectionHelpers;

var make = TestimonialCarouselSection;

export {
  KontentContextConsumer ,
  SectionHeading ,
  RichText ,
  ImageGrid ,
  Carousel ,
  Slide ,
  SlideBg ,
  SlideContent ,
  SlideIcon ,
  SlideDescription ,
  SlideFooter ,
  DeviceSizeImage ,
  Link$1 as Link,
  ImageCollectionHelpers ,
  Styles ,
  Builder ,
  make ,
}
/* SectionHeading Not a pure module */
