import { css, cx } from '@emotion/css';
import { tabletSm, belowLargeScreen } from '@prezzee/ribbon/src/theme/Theme__Responsive.bs.js';

enum Fonts {
  sofia_pro = 'sofia-pro, sans-serif',
  dm_serif = 'DM Serif Display, sans-serif',
}

export const getFontFamily = (codename: string) => Fonts[codename];

export const isSofiaPro = codename => codename === 'sofia_pro';
export const isDmSerif = codename => codename === 'dm_serif';

export const sharedStyles = {
  container: bgColour => css`
    display: flex;
    position: relative;
    background-color: ${bgColour};
  `,
  title: (colour, fontFamily, highlighedColour, tabletBreakpoint) => css`
    color: ${colour};
    font-family: ${getFontFamily(fontFamily)};
    font-weight: ${isDmSerif(fontFamily) ? 400 : 600};

    span {
      font-family: ${Fonts.dm_serif};
      font-style: italic;
      color: ${highlighedColour};
      font-weight: 400;
    }

    br {
      &.desktop {
        @media ${tabletBreakpoint} {
          display: none;
        }
      }
      &.tablet {
        display: none;
        @media ${tabletBreakpoint} {
          display: inline;
        }
        @media ${tabletSm} {
          display: none;
        }
      }
      &.mobile {
        display: none;
        @media ${tabletSm} {
          display: inline;
        }
      }
    }
  `,
  titleSize: {
    xxl: css`
      font-size: 62px;
      line-height: 72px;

      @media ${belowLargeScreen} {
        font-size: 52px;
        line-height: 62px;
      }

      @media ${tabletSm} {
        font-size: 46px;
        line-height: 56px;
      }
    `,
    xl: css`
      font-size: 52px;
      line-height: 62px;

      @media ${belowLargeScreen} {
        font-size: 42px;
        line-height: 50px;
      }

      @media ${tabletSm} {
        font-size: 42px;
        line-height: 52px;
      }
    `,
    l: css`
      font-size: 42px;
      line-height: 50px;

      @media ${belowLargeScreen} {
        font-size: 36px;
        line-height: 44px;
      }
    `,
    m: css`
      font-size: 36px;
      line-height: 44px;

      @media ${belowLargeScreen} {
        font-size: 32px;
        line-height: 40px;
      }
    `,
  },

  description: (colour, tabletBreakpoint) => css`
    color: ${colour};
    margin-top: 12px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;

    @media ${tabletBreakpoint} {
      margin-top: 8px;
    }

    br {
      &.desktop {
        @media ${tabletBreakpoint} {
          display: none;
        }
      }
      &.tablet {
        display: none;
        @media ${tabletBreakpoint} {
          display: inline;
        }
        @media ${tabletSm} {
          display: none;
        }
      }
      &.mobile {
        display: none;
        @media ${tabletSm} {
          display: inline;
        }
      }
    }
  `,
  descriptionLarge: css`
    font-size: 22px;
    line-height: 30px;
    font-weight: 400;

    @media ${tabletSm} {
      font-size: 18px;
      line-height: 20px;
    }
  `,
  imageDesktop: tabletBreakpoint => css`
    display: block;

    @media ${tabletBreakpoint} {
      display: none;
    }
  `,
  imageTablet: tabletBreakpoint => css`
    display: none;

    @media ${tabletBreakpoint} {
      display: block;
    }

    @media ${tabletSm} {
      display: none;
    }
  `,
  imageMobile: css`
    display: none;

    @media ${tabletSm} {
      display: block;
    }
  `,
};

const descriptionSizes = {
  regular: sharedStyles.description,
  large: (colour, tabletBreakpoint) =>
    cx(sharedStyles.description(colour, tabletBreakpoint), sharedStyles.descriptionLarge),
};

export const getTitleSize = size => sharedStyles.titleSize[size];

export const getDescriptionStyles = size => descriptionSizes[size];

export const highligtTitle = title => {
  const openBracketIndex = title.indexOf('{');
  const closeBracketIndex = title.indexOf('}');

  const beforeHighlight = title.slice(0, openBracketIndex);
  const highlight = title.slice(openBracketIndex + 1, closeBracketIndex);
  const afterHighlight = title.slice(closeBracketIndex + 1);

  return (
    <>
      {beforeHighlight}
      <span>{highlight}</span>
      {afterHighlight}
    </>
  );
};

export const hightlightTitleAsString = title => title.replace('{', '<span>').replace('}', '</span>');
