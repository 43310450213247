import CmsImage from 'components/CmsImage';
import { FC, useEffect, useRef } from 'react';
import { css } from '@emotion/css';

import colors from '@prezzee/ribbon-ui/lib/theme/foundations/colors';
import breakpoints from '@prezzee/ribbon-ui/lib/theme/foundations/breakpoints';
import { tabletSm } from '@prezzee/ribbon/src/theme/Theme__Responsive.bs.js';

import { t as blockType } from 'api/graphql/SellingPointsBlock/Gql__SellingPointsBlock__Decoder.gen';
import { t as itemType } from 'api/graphql/SellingPointsBlockItem/Gql__SellingPointsBlockItem__Decoder.gen';
import useSmartLink from 'hooks/useSmartLink';
import { highlightText } from 'common/highlightText';
import { curlyBrackets } from 'utils/Regex';

interface Props {
  block: blockType;
}

type ItemProps = itemType;

const style = {
  container: bgColor => css`
    background-color: ${bgColor};
  `,
  wrapper: css`
    max-width: 1014px;
    padding: 56px 32px 30px;
    margin: 0 auto;

    @media (max-width: ${breakpoints.lg}) {
      padding: 48px 32px 30px;
    }

    @media ${tabletSm} {
      padding: 20px 20px 20px;
    }
  `,
  title: css`
    font-family: 'DM Serif Display';
    font-size: 42px;
    line-height: 62px;
    color: ${colors.purple[400]};
    margin: 0 0 10px;
    text-align: center;

    span {
      font-style: italic;
      position: relative;
      white-space: nowrap;

      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        transform: translate(0, 100%);
        width: 100%;
        padding-top: 7%;
        background: url('/images/curved_red_underline.svg') center center no-repeat;
        background-size: contain;
      }
    }
  `,
  item: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 60px 0 0;

    :first-of-type {
      margin: 70px 0 0;
    }
    :nth-of-type(even) {
      flex-direction: row-reverse;
    }

    @media ${tabletSm} {
      flex-flow: column-reverse;

      :nth-of-type(even) {
        flex-flow: column-reverse;
      }

      :first-of-type {
        margin: 50px 0 0;
      }

      margin: 24px 0 0;
    }
  `,
  itemContent: css`
    width: 42%;

    @media (max-width: ${breakpoints.lg}) {
      width: 48%;
    }

    @media ${tabletSm} {
      width: 100%;
      margin: 24px 0 0;
      text-align: center;
    }
  `,
  itemTitle: css`
    color: ${colors.gray[800]};
    font-size: 32px;
    line-height: 42px;
    font-weight: 600;

    span {
      font-family: 'DM Serif Display';
      color: ${colors.purple[400]};
      font-style: italic;
      font-weight: 400;
    }
  `,
  itemDescription: css`
    margin: 6px 0 0;
    color: ${colors.gray[600]};
  `,
  itemImage: css`
    width: 37%;

    @media ${tabletSm} {
      width: 100%;
    }
  `,
};

const SellingPointItem: FC<ItemProps> = ({ image, title, description, video_mp4, video_webm }) => {
  const isVideo = video_mp4 || video_webm;
  const videoRef = useRef<HTMLVideoElement>(null);
  const isHighlight = curlyBrackets.test(title);

  useEffect(() => {
    if (isVideo && videoRef.current) {
      var sourceElements = Array.from(videoRef.current.children);
      sourceElements.forEach((element: HTMLSourceElement) => {
        if (element.tagName === 'SOURCE') {
          element.src = element.dataset.src;
        }
      });
      videoRef.current.load();
    }
  }, []);

  return (
    <article className={style.item}>
      <div className={style.itemContent}>
        <h3 className={style.itemTitle}>{isHighlight ? highlightText(title) : title}</h3>
        <p className={style.itemDescription}>{description}</p>
      </div>
      {isVideo ? (
        <video
          className={style.itemImage}
          autoPlay={true}
          muted={true}
          loop={true}
          playsInline={true}
          width={image.width}
          height={image.height}
          poster={`${image.url}?fm=png&auto=format`}
          ref={videoRef}
        >
          {video_webm && <source data-src={video_webm.url} type="video/webm" />}
          {video_mp4 && <source data-src={video_mp4.url} type="video/mp4" />}
        </video>
      ) : (
        <CmsImage
          src={image.url}
          alt={image.description}
          width={image.width}
          height={image.height}
          sizes={`${tabletSm} 100vw, (max-width: ${breakpoints.lg}) 47vw, 360px`}
          className={style.itemImage}
        />
      )}
    </article>
  );
};

const SellingPointsSection: FC<Props> = ({ block: { smartLink, title, backgroundColour, items } }) => {
  const spotlightProps = useSmartLink(smartLink);
  const isHighlightTitle = curlyBrackets.test(title);

  return (
    <section className={style.container(backgroundColour)} {...spotlightProps}>
      <div className={style.wrapper}>
        <h2 className={style.title}>{isHighlightTitle ? highlightText(title) : title}</h2>
        {items.map(item => (
          <SellingPointItem {...item} key={item.smartLink.codename} />
        ))}
      </div>
    </section>
  );
};

export default SellingPointsSection;
