import { FC } from 'react';
import { css } from '@emotion/css';
import Link from 'next/link';

import Typography from '@prezzee/ribbon-ui/lib/components/Typography';
import Button from '@prezzee/ribbon-ui/lib/components/Button';
import breakpoints from '@prezzee/ribbon-ui/lib/theme/foundations/breakpoints';
import { tabletSm } from '@prezzee/ribbon/src/theme/Theme__Responsive.bs.js';

import useSmartLink from 'hooks/useSmartLink';
import { t as assetType } from 'api/graphql/AssetType.gen';

import { t as smartLinkType } from './SmartLink.gen';

interface Props {
  block: {
    title: string;
    backgroundColour: string;
    bgImageDesktop: assetType;
    bgImageTablet: assetType;
    bgImageMobile: assetType;
    cta?: {
      cta_url: string;
      cta_text: string;
    };
    smartLink: smartLinkType;
  };
}

const getBgImage = url => (url !== '' ? `url(${url}?fm=png&auto=format)` : 'none');

const style = {
  container: (bgColor, bgDesktop, bgTablet, bgMobile) => css`
    padding: 118px 20px 100px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: ${bgColor};
    background-image: ${getBgImage(bgDesktop)};
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;

    @media (max-width: ${breakpoints.lg}) {
      background-image: ${getBgImage(bgTablet)};
    }

    @media ${tabletSm} {
      background-image: ${getBgImage(bgMobile)};
      padding: 60px 20px 50px;
    }
  `,
  button: css`
    margin-top: 35px;
  `,
};

const NarrowCTASection: FC<Props> = ({
  block: { title, backgroundColour, bgImageDesktop, bgImageTablet, bgImageMobile, cta, smartLink },
}) => {
  const spotlightProps = useSmartLink(smartLink);

  return (
    <section
      className={style.container(backgroundColour, bgImageDesktop.url, bgImageTablet.url, bgImageMobile.url)}
      {...spotlightProps}
    >
      <Typography variant={'h2'}>{title}</Typography>
      <Link href={cta.cta_url} passHref>
        <Button variant="secondary" paddingY={'13px'} paddingX={'36px'} height={'auto'} className={style.button}>
          <a>{cta.cta_text}</a>
        </Button>
      </Link>
    </section>
  );
};

export default NarrowCTASection;
