export const highlightText = text => {
  const openBracketIndex = text.indexOf('{');
  const closeBracketIndex = text.indexOf('}');

  const beforeHighlight = text.slice(0, openBracketIndex);
  const highlight = text.slice(openBracketIndex + 1, closeBracketIndex);
  const afterHighlight = text.slice(closeBracketIndex + 1);

  return (
    <>
      {beforeHighlight}
      <span>{highlight}</span>
      {afterHighlight}
    </>
  );
};
