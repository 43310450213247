// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cn from "rescript-classnames/src/Cn.bs.js";
import * as Pino from "../../../bindings/Pino.bs.js";
import * as BsCss from "../../../bindings/BsCss.bs.js";
import * as Curry from "@rescript/std/lib/es6/curry.js";
import * as Regex from "../../../utils/Regex.bs.js";
import * as React from "react";
import * as Js_dict from "@rescript/std/lib/es6/js_dict.js";
import * as Segment from "../../../bindings/Segment.bs.js";
import * as Caml_obj from "@rescript/std/lib/es6/caml_obj.js";
import * as CmsImage from "../../CmsImage.bs.js";
import * as Js_option from "@rescript/std/lib/es6/js_option.js";
import Link from "next/link";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as Js_promise from "@rescript/std/lib/es6/js_promise.js";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as FormHelpers from "../../../utils/FormHelpers.bs.js";
import * as IndexStyles from "../index/IndexStyles.bs.js";
import * as ReactHookForm from "../../../bindings/ReactHookForm.bs.js";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Text$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/Text/Text.bs.js";
import * as PrezzeePublicClient from "../../../api/PrezzeePublicClient.bs.js";
import * as Button$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/Button/Button.bs.js";
import * as Kontent$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/Kontent/Kontent.bs.js";
import * as KontentContextProvider from "../index/KontentContextProvider.bs.js";
import * as RichText$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/RichText/RichText.bs.js";
import * as TextField$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/TextField/TextField.bs.js";
import * as Theme__Color$PrezzeeRibbon from "@prezzee/ribbon/src/theme/Theme__Color.bs.js";
import * as Theme__Layout$PrezzeeRibbon from "@prezzee/ribbon/src/theme/Theme__Layout.bs.js";
import * as ReactFontawesome from "@fortawesome/react-fontawesome";
import * as Theme__Responsive$PrezzeeRibbon from "@prezzee/ribbon/src/theme/Theme__Responsive.bs.js";
import * as Theme__Typography$PrezzeeRibbon from "@prezzee/ribbon/src/theme/Theme__Typography.bs.js";
import * as FaCheckCircle from "@fortawesome/free-solid-svg-icons/faCheckCircle";
import * as FaExclamationCircle from "@fortawesome/free-solid-svg-icons/faExclamationCircle";

var wrapper = Curry._1(BsCss.style, {
      hd: BsCss.display("flex"),
      tl: {
        hd: BsCss.justifyContent("spaceBetween"),
        tl: {
          hd: BsCss.paddingTop(BsCss.px(70)),
          tl: {
            hd: BsCss.paddingBottom(BsCss.px(70)),
            tl: {
              hd: BsCss.marginTop(BsCss.px(-30)),
              tl: {
                hd: BsCss.color(Theme__Color$PrezzeeRibbon.Business.bodyTextGrey),
                tl: {
                  hd: BsCss.media(Theme__Responsive$PrezzeeRibbon.tabletSm, {
                        hd: BsCss.flexDirection("column"),
                        tl: {
                          hd: BsCss.paddingTop(BsCss.px(40)),
                          tl: {
                            hd: BsCss.paddingBottom(BsCss.px(40)),
                            tl: /* [] */0
                          }
                        }
                      }),
                  tl: {
                    hd: BsCss.media(Theme__Responsive$PrezzeeRibbon.desktop, {
                          hd: BsCss.paddingTop(BsCss.px(120)),
                          tl: {
                            hd: BsCss.paddingBottom(BsCss.px(120)),
                            tl: /* [] */0
                          }
                        }),
                    tl: {
                      hd: BsCss.children({
                            hd: BsCss.marginTop(BsCss.px(30)),
                            tl: {
                              hd: BsCss.media(Theme__Responsive$PrezzeeRibbon.tabletSmUp, {
                                    hd: BsCss.width({
                                          NAME: "calc",
                                          VAL: [
                                            "sub",
                                            BsCss.pct(50),
                                            BsCss.px(30)
                                          ]
                                        }),
                                    tl: /* [] */0
                                  }),
                              tl: /* [] */0
                            }
                          }),
                      tl: {
                        hd: BsCss.selector("h2", {
                              hd: BsCss.marginBottom(BsCss.px(20)),
                              tl: /* [] */0
                            }),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var formContainer = Curry._1(BsCss.style, {
      hd: BsCss.display("flex"),
      tl: {
        hd: BsCss.alignItems("center"),
        tl: {
          hd: BsCss.justifyContent("center"),
          tl: {
            hd: BsCss.alignSelf("center"),
            tl: {
              hd: BsCss.borderRadius(Theme__Layout$PrezzeeRibbon.Personal.borderRadius),
              tl: {
                hd: BsCss.padding(BsCss.px(24)),
                tl: {
                  hd: BsCss.boxShadow(Css_Legacy_Core.Shadow.box(BsCss.px(0), BsCss.px(40), BsCss.px(200), undefined, undefined, BsCss.rgba(0, 0, 0, BsCss.pct(8)))),
                  tl: {
                    hd: BsCss.media(Theme__Responsive$PrezzeeRibbon.desktop, {
                          hd: BsCss.padding(BsCss.px(40)),
                          tl: /* [] */0
                        }),
                    tl: /* [] */0
                  }
                }
              }
            }
          }
        }
      }
    });

var form = Curry._1(BsCss.style, {
      hd: BsCss.display("flex"),
      tl: {
        hd: BsCss.flexWrap("wrap"),
        tl: {
          hd: BsCss.marginTop(BsCss.px(-20)),
          tl: {
            hd: BsCss.margin4(BsCss.px(-20), BsCss.px(-10), BsCss.zero, BsCss.px(-10)),
            tl: {
              hd: BsCss.children({
                    hd: BsCss.margin4(BsCss.px(20), BsCss.px(10), BsCss.zero, BsCss.px(10)),
                    tl: {
                      hd: BsCss.width(BsCss.pct(100)),
                      tl: /* [] */0
                    }
                  }),
              tl: /* [] */0
            }
          }
        }
      }
    });

var title = Curry._1(BsCss.merge, {
      hd: Theme__Typography$PrezzeeRibbon.Styles.style1,
      tl: {
        hd: Curry._1(BsCss.style, {
              hd: BsCss.important(BsCss.marginBottom(BsCss.px(20))),
              tl: {
                hd: BsCss.color(Theme__Color$PrezzeeRibbon.Business.charcoal),
                tl: {
                  hd: BsCss.media(Theme__Responsive$PrezzeeRibbon.smallScreenOrMobile, {
                        hd: BsCss.important(BsCss.fontSize(BsCss.px(32))),
                        tl: {
                          hd: BsCss.important(BsCss.marginBottom(BsCss.px(10))),
                          tl: /* [] */0
                        }
                      }),
                  tl: /* [] */0
                }
              }
            }),
        tl: /* [] */0
      }
    });

var linkContainer = Curry._1(BsCss.style, {
      hd: BsCss.display("block"),
      tl: {
        hd: BsCss.fontWeight({
              NAME: "num",
              VAL: 500
            }),
        tl: {
          hd: BsCss.marginBottom(BsCss.px(15)),
          tl: /* [] */0
        }
      }
    });

var success = Curry._1(BsCss.style, {
      hd: BsCss.color(Theme__Color$PrezzeeRibbon.Business.prezzeePurple),
      tl: /* [] */0
    });

var error = Curry._1(BsCss.style, {
      hd: BsCss.color(BsCss.hex("FF747C")),
      tl: /* [] */0
    });

var postMessageContainer = Curry._1(BsCss.style, {
      hd: BsCss.maxWidth(BsCss.px(328)),
      tl: {
        hd: BsCss.textAlign("center"),
        tl: {
          hd: BsCss.selector("svg", {
                hd: BsCss.fontSize(BsCss.px(50)),
                tl: /* [] */0
              }),
          tl: {
            hd: BsCss.children({
                  hd: BsCss.important(BsCss.marginBottom(BsCss.px(20))),
                  tl: {
                    hd: BsCss.lastChild({
                          hd: BsCss.important(BsCss.marginBottom(BsCss.zero)),
                          tl: /* [] */0
                        }),
                    tl: /* [] */0
                  }
                }),
            tl: /* [] */0
          }
        }
      }
    });

var ctaContainer = Curry._1(BsCss.style, {
      hd: BsCss.display("flex"),
      tl: {
        hd: BsCss.flexWrap("wrap"),
        tl: {
          hd: BsCss.margin2(BsCss.zero, BsCss.px(-10)),
          tl: {
            hd: BsCss.children({
                  hd: BsCss.margin4(BsCss.px(20), BsCss.px(10), BsCss.zero, BsCss.px(10)),
                  tl: /* [] */0
                }),
            tl: /* [] */0
          }
        }
      }
    });

var emailIcon = Curry._1(BsCss.style, {
      hd: BsCss.transform({
            NAME: "translateY",
            VAL: BsCss.px(3)
          }),
      tl: /* [] */0
    });

var phoneIcon = Curry._1(BsCss.style, {
      hd: BsCss.transform({
            NAME: "translateY",
            VAL: BsCss.px(2)
          }),
      tl: /* [] */0
    });

var iconContainer = Curry._1(BsCss.style, {
      hd: BsCss.display("inlineBlock"),
      tl: {
        hd: BsCss.marginRight(BsCss.px(12)),
        tl: {
          hd: BsCss.fontSize(BsCss.px(22)),
          tl: {
            hd: BsCss.child("div")({
                  hd: BsCss.marginBottom("zero"),
                  tl: /* [] */0
                }),
            tl: /* [] */0
          }
        }
      }
    });

var Styles = {
  wrapper: wrapper,
  formContainer: formContainer,
  form: form,
  title: title,
  linkContainer: linkContainer,
  success: success,
  error: error,
  postMessageContainer: postMessageContainer,
  ctaContainer: ctaContainer,
  emailIcon: emailIcon,
  phoneIcon: phoneIcon,
  iconContainer: iconContainer
};

function textInput(message, required) {
  return {
          required: {
            value: required,
            message: message
          }
        };
}

function lastNameInput(message, required) {
  return {
          required: {
            value: required,
            message: message
          }
        };
}

function emailInput(message, required) {
  return {
          required: {
            value: required,
            message: message
          },
          pattern: {
            value: Regex.emailRegex,
            message: "Please enter a valid email address"
          }
        };
}

function phoneInput(message, required) {
  return {
          required: {
            value: required,
            message: message
          },
          validate: (function (number) {
              if (number == null) {
                return false;
              }
              var cleanNumber = number.replace(/\s*/g, "");
              if (Regex.phoneNumberRegex.test(cleanNumber)) {
                return true;
              } else if (required) {
                return false;
              } else {
                return number === "";
              }
            })
        };
}

function segmentTrackFormSubmitSuccess(formValues, isSuccessful) {
  return {
          business_name: formValues.company,
          email: formValues.email,
          first_name: formValues.firstname,
          last_name: formValues.lastname,
          lead_type: "contact us business home",
          phone: formValues.phone,
          store: "business",
          is_successful: isSuccessful
        };
}

function BusinessContactFormSection(props) {
  var block = props.block;
  var match = React.useState(function () {
        return false;
      });
  var setIsLoading = match[1];
  var match$1 = React.useState(function () {
        return false;
      });
  var setSuccess = match$1[1];
  var match$2 = React.useState(function () {
        return false;
      });
  var setError = match$2[1];
  var form$1 = ReactHookForm.useForm({
        mode: "onTouched"
      }, undefined);
  var onSubmit = React.useCallback((function (data) {
          Curry._1(setIsLoading, (function (param) {
                  return true;
                }));
          var formValues = Curry._1(form$1.getValues, undefined);
          var requestFields = Belt_Array.map(Object.keys(data), (function (field) {
                  return Js_dict.fromList({
                              hd: [
                                "name",
                                field
                              ],
                              tl: {
                                hd: [
                                  "value",
                                  Js_option.getWithDefault("", Js_dict.get(data, field))
                                ],
                                tl: /* [] */0
                              }
                            });
                }));
          Js_promise.then_((function (result) {
                  if (result.TAG === /* Ok */0) {
                    Curry._1(setIsLoading, (function (param) {
                            return false;
                          }));
                    Segment.businessLeadSubmitted(segmentTrackFormSubmitSuccess(formValues, true));
                    return Promise.resolve(Curry._1(setSuccess, (function (param) {
                                      return true;
                                    })));
                  }
                  Curry._1(setIsLoading, (function (param) {
                          return false;
                        }));
                  Segment.businessLeadSubmitted(segmentTrackFormSubmitSuccess(formValues, false));
                  Curry._1(setError, (function (param) {
                          return true;
                        }));
                  Pino.logger.error({
                        err: result._0,
                        source: "Hubspot Form API"
                      }, "error: Could not submit Business Contact Form");
                  return Promise.resolve(undefined);
                }), PrezzeePublicClient.businessContact(requestFields));
        }), []);
  var smart_link = block.smart_link;
  var phone = block.phone;
  var phone_icon = block.phone_icon;
  var email = block.email;
  var email_icon = block.email_icon;
  var formFields = Belt_Array.mapWithIndex(block.fields, (function (index, field) {
          var smartLink = field.smartLink;
          var required = field.required;
          var label = field.label;
          var type_ = field.type_;
          var name = field.name;
          var key = index.toString();
          var inputType;
          switch (type_) {
            case "email" :
                inputType = emailInput;
                break;
            case "tel" :
                inputType = phoneInput;
                break;
            default:
              inputType = textInput;
          }
          var errorText = FormHelpers.getFieldError(form$1.errors, name);
          var label_ = required ? label + "*" : label;
          return JsxRuntime.jsx(KontentContextProvider.make, {
                      children: JsxRuntime.jsx(Kontent$PrezzeeRibbon.Consumer.make, {
                            children: JsxRuntime.jsx("div", {
                                  children: JsxRuntime.jsx(TextField$PrezzeeRibbon.make, {
                                        className: Cn.make(["w-full"]),
                                        label: label_,
                                        name: name,
                                        placeholder: field.placeholder,
                                        required: required,
                                        type_: type_,
                                        error: Belt_Option.isSome(FormHelpers.getFieldError(form$1.errors, name)),
                                        helperText: Caml_obj.equal(errorText, "") && type_ === "tel" ? "Please enter a valid phone number" : errorText,
                                        inputRef: Caml_option.some(Curry._1(form$1.register, Curry._2(inputType, field.validation_message, required)))
                                      }, key)
                                })
                          }),
                      itemId: smartLink.itemId,
                      elementCodename: smartLink.codename
                    });
        }));
  var ctaButtons = Belt_Array.mapWithIndex(block.body_ctas, (function (i, b) {
          var target = b.open_in_new_window ? "_blank" : "";
          var match = b.smartLink;
          return JsxRuntime.jsx(KontentContextProvider.make, {
                      children: JsxRuntime.jsx(Kontent$PrezzeeRibbon.Consumer.make, {
                            children: JsxRuntime.jsx("div", {
                                  children: JsxRuntime.jsx(Button$PrezzeeRibbon.make, {
                                        children: JsxRuntime.jsx(Link, {
                                              href: b.cta_url,
                                              prefetch: false,
                                              children: JsxRuntime.jsx("a", {
                                                    children: JsxRuntime.jsx("button", {
                                                          children: b.cta_text
                                                        }),
                                                    target: target
                                                  })
                                            }),
                                        fill: b.fill,
                                        colour: b.colour,
                                        variant: b.variant
                                      }, String(i))
                                })
                          }),
                      itemId: match.itemId,
                      elementCodename: match.codename
                    }, String(i));
        }));
  var success$1 = JsxRuntime.jsxs("div", {
        children: [
          JsxRuntime.jsx(ReactFontawesome.FontAwesomeIcon, {
                icon: FaCheckCircle.faCheckCircle,
                className: success
              }),
          JsxRuntime.jsx("div", {
                children: JsxRuntime.jsx(Text$PrezzeeRibbon.make, {
                      children: "Thank you!"
                    }),
                className: Theme__Typography$PrezzeeRibbon.Styles.style1
              }),
          JsxRuntime.jsx("p", {
                children: JsxRuntime.jsx(Text$PrezzeeRibbon.make, {
                      children: "Your request has been submitted and a team member will contact you shortly."
                    })
              })
        ],
        className: postMessageContainer
      });
  var error$1 = JsxRuntime.jsxs("div", {
        children: [
          JsxRuntime.jsx(ReactFontawesome.FontAwesomeIcon, {
                icon: FaExclamationCircle.faExclamationCircle,
                className: error
              }),
          JsxRuntime.jsx("div", {
                children: JsxRuntime.jsx(Text$PrezzeeRibbon.make, {
                      children: "Failed to send your request"
                    }),
                className: Theme__Typography$PrezzeeRibbon.Styles.style1
              }),
          JsxRuntime.jsx("p", {
                children: JsxRuntime.jsx(Text$PrezzeeRibbon.make, {
                      children: "Your request has failed to submit, please try again shortly."
                    })
              })
        ],
        className: postMessageContainer
      });
  var form$2 = JsxRuntime.jsxs("form", {
        children: [
          formFields,
          JsxRuntime.jsx(Button$PrezzeeRibbon.make, {
                children: JsxRuntime.jsx("button", {
                      children: JsxRuntime.jsx(Text$PrezzeeRibbon.make, {
                            children: match[0] ? "Sending..." : block.submit_cta_text
                          }),
                      type: "submit"
                    }),
                colour: /* BusinessPurple */3,
                shadow: /* Normal */1
              })
        ],
        className: form,
        onSubmit: Curry._2(form$1.handleSubmit, onSubmit, FormHelpers.onError)
      });
  var emailIcon$1;
  if (email_icon !== undefined) {
    var height = email_icon.height;
    var width = email_icon.width;
    emailIcon$1 = JsxRuntime.jsx("span", {
          children: JsxRuntime.jsx(CmsImage.make, {
                src: email_icon.url,
                alt: email_icon.description,
                width: width !== 0 ? width : 20,
                height: height !== 0 ? height : 20
              }),
          className: Cn.make([
                iconContainer,
                emailIcon
              ])
        });
  } else {
    emailIcon$1 = null;
  }
  var phoneIcon$1;
  if (phone_icon !== undefined) {
    var height$1 = phone_icon.height;
    var width$1 = phone_icon.width;
    phoneIcon$1 = JsxRuntime.jsx("span", {
          children: JsxRuntime.jsx(CmsImage.make, {
                src: phone_icon.url,
                alt: phone_icon.description,
                width: width$1 !== 0 ? width$1 : 20,
                height: height$1 !== 0 ? height$1 : 20
              }),
          className: Cn.make([
                iconContainer,
                phoneIcon
              ])
        });
  } else {
    phoneIcon$1 = null;
  }
  return JsxRuntime.jsx(KontentContextProvider.make, {
              children: JsxRuntime.jsx(Kontent$PrezzeeRibbon.Consumer.make, {
                    children: JsxRuntime.jsx("div", {
                          children: JsxRuntime.jsxs("div", {
                                children: [
                                  JsxRuntime.jsxs("div", {
                                        children: [
                                          JsxRuntime.jsx("div", {
                                                children: JsxRuntime.jsx(RichText$PrezzeeRibbon.make, {
                                                      children: block.title
                                                    }),
                                                className: title
                                              }),
                                          Caml_obj.notequal(emailIcon$1, null) ? JsxRuntime.jsx("div", {
                                                  children: JsxRuntime.jsxs("a", {
                                                        children: [
                                                          emailIcon$1,
                                                          JsxRuntime.jsx(Text$PrezzeeRibbon.make, {
                                                                children: email
                                                              })
                                                        ],
                                                        href: "mailto:" + email
                                                      }),
                                                  className: linkContainer
                                                }) : null,
                                          Caml_obj.notequal(phoneIcon$1, null) ? JsxRuntime.jsx("div", {
                                                  children: JsxRuntime.jsxs("a", {
                                                        children: [
                                                          phoneIcon$1,
                                                          JsxRuntime.jsx(Text$PrezzeeRibbon.make, {
                                                                children: phone
                                                              })
                                                        ],
                                                        href: "tel:" + phone
                                                      }),
                                                  className: linkContainer
                                                }) : null,
                                          JsxRuntime.jsx(RichText$PrezzeeRibbon.make, {
                                                children: block.body_text
                                              }),
                                          JsxRuntime.jsx("div", {
                                                children: ctaButtons,
                                                className: ctaContainer
                                              })
                                        ]
                                      }),
                                  JsxRuntime.jsx("div", {
                                        children: match$1[0] ? success$1 : (
                                            match$2[0] ? error$1 : form$2
                                          ),
                                        className: formContainer
                                      })
                                ],
                                className: wrapper
                              }),
                          className: IndexStyles.section,
                          id: "speak-with-our-team"
                        })
                  }),
              itemId: smart_link.itemId,
              elementCodename: smart_link.codename
            });
}

var KontentContextConsumer;

var RichText;

var make = BusinessContactFormSection;

export {
  KontentContextConsumer ,
  RichText ,
  Styles ,
  textInput ,
  lastNameInput ,
  emailInput ,
  phoneInput ,
  segmentTrackFormSubmitSuccess ,
  make ,
}
/* wrapper Not a pure module */
