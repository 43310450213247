import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from '@prezzee/ribbon-ui/lib/components/Modal';
import Typography from '@prezzee/ribbon-ui/lib/components/Typography';
import { SystemProps } from '@chakra-ui/system';
import Button from '@prezzee/ribbon-ui/lib/components/Button';
import Box from '@prezzee/ribbon-ui/lib/components/Box';
import { VStack } from '@prezzee/ribbon-ui/lib/components/Stack';
import useDisclosure from '@prezzee/ribbon-ui/lib/hooks/useDisclosure';
import ChevronLeftIcon from '@prezzee/ribbon-ui/lib/components/icons/ChevronLeftIcon';
import { Fragment } from 'react';
import RetailerListModal from '../RetailerListModal';
import { useQuery } from '@tanstack/react-query';
import zagInfoModal from 'api/queries/zagInfoModal';
import { EnvUtil } from 'common/util.bs';
import ReactHtmlParser from 'react-html-parser';

interface ZagExplanationModalProps {
  onClose: () => void;
  sku?: { uid: string };
}

const ZagExplanationModal = ({ sku, onClose }: ZagExplanationModalProps) => {
  const retailerListDialog = useDisclosure();
  const { data, isError, isLoading } = useQuery(['zagInfoModal'], () =>
    zagInfoModal(EnvUtil.getCmsLanguageFromRegion(), false)
  );

  const desktopScreen = window.matchMedia('(min-width: 577px)');

  return (
    <Fragment>
      {!retailerListDialog.isOpen && (
        <Modal isOpen onClose={onClose} isCentered size={desktopScreen.matches ? 'md' : 'xs'} scrollBehavior="inside">
          <ModalOverlay />
          <ModalContent maxWidth="800px" margin="20px">
            <ModalHeader pb={0}>
              <Typography variant="h2" color="gray.800" fontSize="24" fontWeight="bold">
                Prezzee Plus eGift Card
              </Typography>
            </ModalHeader>
            <ModalCloseButton mt={3} />
            <ModalBody>
              {isLoading && (
                <Box display="flex" justifyContent="center">
                  Loading
                </Box>
              )}

              {isError && (
                <Box display="flex" justifyContent="center">
                  Failed to fetch data, please try again later
                </Box>
              )}
              {data &&
                ReactHtmlParser(data.item.elements.content.value, {
                  transform: node => {
                    // Should move this to util package when we have the chance
                    if (node?.type === 'tag' && node?.name === 'a') {
                      return (
                        <Button as="a" variant="link" textDecoration="underline" fontSize="sm" {...node.attribs}>
                          {node?.children.map(child => {
                            if (child.type === 'text') {
                              return child.data;
                            }
                            return null;
                          })}
                        </Button>
                      );
                    }

                    if (node?.type === 'text' && node?.data?.includes('{0}')) {
                      const tempStrArr = node?.data?.split('{0}');
                      return (
                        <>
                          {tempStrArr?.[0]}
                          <Button
                            variant="link"
                            onClick={retailerListDialog.onOpen}
                            fontSize="md"
                            textDecoration="underline"
                            mb="4"
                          >
                            {data.item.elements.participating_brands_link.value}
                          </Button>
                        </>
                      );
                    }
                  },
                })}
              <VStack {...buttonStackStyles}>
                <Button variant="primary" onClick={onClose} width="full">
                  {data?.item?.elements?.cta_text?.value}
                </Button>
              </VStack>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
      <RetailerListModal
        showDefaultStore
        isOpen={retailerListDialog.isOpen}
        onClose={() => {
          retailerListDialog.onClose();
          onClose();
        }}
        uid={sku?.uid}
        title={
          <>
            <Button
              leftIcon={
                <Box>
                  <ChevronLeftIcon />
                </Box>
              }
              variant="unstyled"
              onClick={retailerListDialog.onClose}
            >
              Back
            </Button>
            <br />
            Available brands
          </>
        }
      />
    </Fragment>
  );
};

const buttonStackStyles: SystemProps = {
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  mb: 4,
};

export default ZagExplanationModal;
