// Generated by ReScript, PLEASE EDIT WITH CARE

import ShopBySkuSectionTsx from "./ShopBySkuSection.tsx";

var make = ShopBySkuSectionTsx;

var Button;

var KontentContextConsumer;

var Link;

export {
  Button ,
  KontentContextConsumer ,
  Link ,
  make ,
}
/* make Not a pure module */
