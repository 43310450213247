import React from 'react';

import Typography from '@prezzee/typography';
import { css } from '@emotion/css';

import { Maybe } from 'models/types';
import { Property as cssProperty } from 'csstype';
import useSmartLink from 'hooks/useSmartLink';
import { t as SmartLink } from '../../library/SmartLink.gen';

const classes = {
  root: css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  }),
  screen: (bgColor: string, alignment: string, height?: Maybe<number>, width?: Maybe<number>) => {
    const defaultWidth = '100%';
    const defaultHeight = '300px';
    return css({
      display: 'flex',
      height: height ? `${height}px` : defaultHeight,
      width: width ? `${width}px` : defaultWidth,
      backgroundColor: bgColor,
      justifyContent: alignment || 'center',
      alignItems: 'center',
    });
  },
  content: css({
    display: 'flex',
    paddingLeft: '20px',
    paddingRight: '20px',
    flexDirection: 'column',
    justifyContent: 'center',
  }),
  title: {
    root: (alignment: string) =>
      css({
        display: 'flex',
        justifyContent: alignment,
        paddingBottom: '10px',
      }),
    text: (colour: string, alignment: string) =>
      css({
        color: `${colour} !important`,
        textAlign: ({
          left: 'start',
          center: 'center',
          right: 'end',
        }[alignment] || 'center') as cssProperty.TextAlign,
      }),
  },
  subtitle: {
    root: (alignment: string) =>
      css({
        display: 'flex',
        justifyContent: alignment,
      }),
    text: (colour: string, alignment: string) =>
      css({
        color: `${colour} !important`,
        fontSize: '16px !important',
        fontWeight: `${400} !important` as cssProperty.FontWeight,
        textAlign: ({
          left: 'start',
          center: 'center',
          right: 'end',
        }[alignment] || 'center') as cssProperty.TextAlign,
      }),
  },
};

type Props = {
  block: any;
  key?: Maybe<number>;
};

/**
 * Basic Content Narrow banner
 * @constructor
 */
export const NarrowBannerFull: React.FC<Props> = ({ block, key }) => {
  const spotlightProps = useSmartLink(block.smartLink as SmartLink);

  return (
    <div className={classes.root} key={key} {...spotlightProps}>
      <div className={classes.screen(block.banner_colour, block.alignment, block.banner_height, block.width)}>
        <div className={classes.content}>
          <div className={classes.title.root(block.alignment)}>
            <Typography variant={'h1'} className={classes.title.text('#ffffff', block.alignment)}>
              {block.title}
            </Typography>
          </div>
          <div className={classes.subtitle.root(block.alignment)}>
            <Typography variant={'h3'} className={classes.subtitle.text('#ffffff', block.alignment)}>
              {block.subtitle}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};
