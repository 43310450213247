import { css } from '@emotion/css';
import {
  mobile,
  tabletOrDesktop,
  tabletOrMobile,
  tabletSm,
  tablet,
  desktop,
  smallScreenOrMobile,
} from '@prezzee/ribbon/src/theme/Theme__Responsive.bs.js';
import * as themeColors from '@prezzee/ribbon/src/theme/Theme__Color.bs';

import { spaceRegex } from 'utils/Regex.bs';
import { FC, useEffect, useMemo } from 'react';
import { useRouter } from 'next/router';
import { t as Block_t } from 'api/graphql/SkuCarousel/Gql__SkuCarousel__Decoder__Carousel.gen';
import useSmartLink from 'hooks/useSmartLink';
import { t as Inventory_t } from 'api/Data/InventoryData.gen';
import { Card } from 'models/Models.bs';
import { Card_t } from 'models/Models.gen';
import { cardToProductWithIndex, productListViewed } from 'bindings/Segment.bs';
import SkuCarousel from 'components/library/SkuCarousel';
import { make as Tagline } from '@prezzee/ribbon/src/components/common/Tagline/Tagline.bs.js';
import { make as Button } from '@prezzee/ribbon/src/components/personal/Button/Button.bs.js';
import useSegment from 'hooks/useSegment';
import { Nullable } from 'models/types';
import { getLocalizedString } from 'config/Localization.bs';
import { envUtil } from 'common/util';

const style = {
  carouselPadding: css`
    margin-top: -1rem;
    padding-top: 1rem;
    margin-left: -2rem;
    padding-left: 2rem;
    padding-bottom: 3rem;
    margin-bottom: -1rem;

    @media ${mobile} {
      margin-right: -1.25rem;
      margin-left: -1.25rem;
      padding-left: 1.25rem;
    }
  `,
  buttonMargin: css`
    margin-top: 1rem;
  `,
  wrapper: css`
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    padding-top: 52px;
    padding-bottom: 52px;
    display: flex;
    flex-direction: column;

    .swiper-scrollbar {
      @media ${mobile} {
        width: calc(100% - ${30 * 2}px);
      }
    }

    .swiper-slide-prev {
      opacity: 0;
      transition-property: opacity;
      transition-duration: 300;
    }

    .swiper-slide:first-child > div {
      margin-left: 0px !important;
    }

    .sku-swiper-last-slide .swiper-slide-prev {
      opacity: 0;
    }

    @media ${tabletOrDesktop} {
      padding-left: 1.875rem;
      padding-right: 1.875rem;
    }

    @media ${tabletOrMobile} {
      padding-top: 42px;
      padding-bottom: 42px;
    }

    @media ${tabletSm} {
      padding-top: 32px;
      padding-bottom: 36px;
    }
  `,
  bgLilac: css`
    background-color: #${themeColors.lilac50.VAL};

    .carousel-card {
      background-color: ${themeColors.white.VAL};
    }

    .sku-swiper-overlay {
      background: linear-gradient(-90deg, #${themeColors.lilac50.VAL} 0%, transparent 100%);
    }
  `,
  headingWrapper: css`
    margin-bottom: 21px;
    padding-right: 100px;
    @media ${tablet} {
      margin-bottom: 30px;
    }

    @media ${desktop} {
      margin-bottom: 22px;
    }

    @media ${smallScreenOrMobile} {
      padding-right: 0;
    }
  `,
  heading: css`
    font-size: 28px;
    line-height: 38px;
    font-weight: 600;
    margin-bottom: 6px;

    @media ${tablet} {
      font-size: 32px;
      line-height: 40px;
    }

    @media ${desktop} {
      font-size: 40px;
      line-height: 40px;
    }
  `,
  subheading: css`
    color: #${themeColors.Personal.midGrey.VAL};
    font-size: 1rem;
    line-height: 1.5rem;
    display: none;

    @media ${desktop} {
      display: inline-block;
    }
  `,
};

const getSegmentElementName = (label: string) => `${label.replace(spaceRegex, '_').toLowerCase()}_cta`;

type Props = {
  inventory: Nullable<Inventory_t>;
  block: Block_t & { inventory: Card_t[]; noOfCards: number };
  skuDict: any;
};

const ShopBySkuSection: FC<Props> = ({ block, inventory, skuDict }) => {
  const router = useRouter();
  const { uid, title, button, subtitle, description, background, skuList, smartLink } = block;
  const spotlightProps = useSmartLink(smartLink);
  const { customElementInteraction } = useSegment();

  const cards: Card_t[] = inventory ? inventory.skus.map(card => Card.skuToCard(card)) : block.inventory;
  const pinnedSkuList = useMemo(
    () => (skuList !== '' ? skuList.split(',').map(str => Card.makeSlug(str)) : []),
    [skuList]
  );
  const sectionStyle = background === 'lilac' ? style.bgLilac : '';
  const noOfCards = block.noOfCards
    ? block.noOfCards
    : cards.filter(card => {
        if (card.denominations.length) {
          return card.denominations[0].valueCurrency === envUtil.currencyCode && !card.isSwappable;
        }
      }).length;

  const region = getLocalizedString('region');
  const cardString = noOfCards >= 10 ? Math.floor(noOfCards / 10) * 10 : noOfCards;
  const parsedSubtitle = subtitle.replace('{0}', region).replace('{1}', `${cardString}${noOfCards >= 10 ? '+' : ''}`);

  useEffect(() => {
    const isCustomList = skuList !== '';
    const featuredCards = isCustomList
      ? cards
      : cards.filter(card => card.categories.find(category => category.name === 'Featured'));
    const products = featuredCards.map((card, index) => cardToProductWithIndex(index, card));
    const list_id = parsedSubtitle.replace(spaceRegex, '_').toLowerCase().slice(0, 20);
    const category = isCustomList ? 'Custom' : 'Featured';
    productListViewed({
      list_id,
      category,
      products,
    });
  }, []);

  return (
    <section className={sectionStyle} {...spotlightProps}>
      <div className={style.wrapper}>
        {title !== '' ? <Tagline>{title}</Tagline> : null}
        <div className={style.headingWrapper}>
          <h2 className={style.heading}>{parsedSubtitle}</h2>
          {description !== '' ? (
            <div className={style.subheading} dangerouslySetInnerHTML={{ __html: description }} />
          ) : null}
        </div>
        <SkuCarousel
          className={style.carouselPadding}
          inventory={inventory}
          skuDict={skuDict}
          skuList={pinnedSkuList}
          uid={uid}
          cardsList={inventory ? undefined : block.inventory}
        />
        {button ? (
          <Button variant={button.variant} colour={button.colour} fill={button.fill}>
            <button
              onClick={() => {
                customElementInteraction({
                  element: getSegmentElementName(button.cta_text),
                  journey: 'sku_list',
                  element_href: button.cta_url,
                  user_type: 'consumer',
                });
                router.push(button.cta_url);
              }}
            >
              {button.cta_text}
            </button>
          </Button>
        ) : null}
      </div>
    </section>
  );
};

export default ShopBySkuSection;
