// Generated by ReScript, PLEASE EDIT WITH CARE

import * as BsCss from "../../../bindings/BsCss.bs.js";
import * as Curry from "@rescript/std/lib/es6/curry.js";
import * as Fresnel from "../../../bindings/Fresnel.bs.js";
import * as CmsImage from "../../CmsImage.bs.js";
import Link from "next/link";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as IndexStyles from "../index/IndexStyles.bs.js";
import * as React from "swiper/react";
import * as JsxRuntime from "react/jsx-runtime";
import * as Kontent$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/Kontent/Kontent.bs.js";
import * as KontentContextProvider from "../index/KontentContextProvider.bs.js";
import * as Theme__Responsive$PrezzeeRibbon from "@prezzee/ribbon/src/theme/Theme__Responsive.bs.js";
import * as BoardOfDirectorsCard$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/BoardOfDirectors/BoardOfDirectorsCard.bs.js";
import * as BoardOfDirectorsList$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/BoardOfDirectors/BoardOfDirectorsList.bs.js";
import * as BoardOfDirectorsCarousel$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/BoardOfDirectors/BoardOfDirectorsCarousel.bs.js";

var outerContainer = Curry._1(BsCss.style, {
      hd: BsCss.position("relative"),
      tl: {
        hd: BsCss.marginTop(BsCss.rem(5)),
        tl: /* [] */0
      }
    });

var container = Curry._1(BsCss.merge, {
      hd: IndexStyles.section,
      tl: {
        hd: Curry._1(BsCss.style, {
              hd: BsCss.maxWidth(BsCss.px(1240)),
              tl: {
                hd: BsCss.marginTop(BsCss.px(100)),
                tl: {
                  hd: BsCss.paddingLeft(BsCss.px(20)),
                  tl: {
                    hd: BsCss.paddingRight(BsCss.px(20)),
                    tl: {
                      hd: BsCss.media(Theme__Responsive$PrezzeeRibbon.belowLargeScreen, {
                            hd: BsCss.paddingLeft(BsCss.px(20)),
                            tl: {
                              hd: BsCss.paddingRight(BsCss.px(20)),
                              tl: /* [] */0
                            }
                          }),
                      tl: /* [] */0
                    }
                  }
                }
              }
            }),
        tl: /* [] */0
      }
    });

var heading = Curry._1(BsCss.style, {
      hd: BsCss.fontSize(BsCss.px(40)),
      tl: {
        hd: BsCss.fontWeight({
              NAME: "num",
              VAL: 600
            }),
        tl: {
          hd: BsCss.lineHeight(BsCss.px(50)),
          tl: {
            hd: BsCss.marginBottom(BsCss.rem(1.25)),
            tl: /* [] */0
          }
        }
      }
    });

var arrowStyles = Curry._1(BsCss.style, {
      hd: BsCss.top("zero"),
      tl: {
        hd: BsCss.right(BsCss.px(30)),
        tl: {
          hd: BsCss.position("absolute"),
          tl: {
            hd: BsCss.zIndex(10),
            tl: /* [] */0
          }
        }
      }
    });

var cardGutter = Curry._1(BsCss.style, {
      hd: BsCss.marginRight(BsCss.rem(0.5)),
      tl: {
        hd: BsCss.marginBottom(BsCss.px(7)),
        tl: {
          hd: BsCss.media(Theme__Responsive$PrezzeeRibbon.smallScreenOrMobile, {
                hd: BsCss.marginRight(BsCss.rem(0)),
                tl: /* [] */0
              }),
          tl: /* [] */0
        }
      }
    });

var listItem = Curry._1(BsCss.style, {
      hd: BsCss.maxWidth(BsCss.px(650)),
      tl: {
        hd: BsCss.marginLeft("auto"),
        tl: {
          hd: BsCss.marginRight("auto"),
          tl: /* [] */0
        }
      }
    });

var Styles = {
  outerContainer: outerContainer,
  container: container,
  heading: heading,
  arrowStyles: arrowStyles,
  cardGutter: cardGutter,
  listItem: listItem
};

function BoardOfDirectorsSection(props) {
  var block = props.block;
  var smartLink = block.smartLink;
  var title = block.title;
  var blockTitle = title === "" ? null : JsxRuntime.jsx("div", {
          children: JsxRuntime.jsx("h2", {
                children: title,
                className: heading
              }),
          className: container
        });
  var boardOfDirectorsCards = Belt_Array.map(block.board_of_directors_cards, (function (param) {
          var smartLink = param.smartLink;
          var link = param.link;
          var title = param.title;
          var name = param.name;
          var photo = param.photo;
          var img = JsxRuntime.jsx(CmsImage.make, {
                src: photo.url,
                alt: name,
                width: photo.width,
                height: photo.height,
                sizes: "" + Theme__Responsive$PrezzeeRibbon.smallScreenOrMobile + " 100vw, " + Theme__Responsive$PrezzeeRibbon.tabletOrMobile + " 255px, 425px"
              });
          var card = link !== undefined ? JsxRuntime.jsx(Link, {
                  href: link,
                  prefetch: false,
                  children: JsxRuntime.jsx("a", {
                        children: JsxRuntime.jsx(BoardOfDirectorsCard$PrezzeeRibbon.make, {
                              name: name,
                              title: title,
                              photo: img
                            })
                      })
                }) : JsxRuntime.jsx(BoardOfDirectorsCard$PrezzeeRibbon.make, {
                  name: name,
                  title: title,
                  photo: img
                });
          return JsxRuntime.jsx(KontentContextProvider.make, {
                      children: JsxRuntime.jsx("div", {
                            children: card,
                            className: cardGutter
                          }),
                      itemId: smartLink.itemId,
                      elementCodename: smartLink.codename
                    });
        }));
  return JsxRuntime.jsx(KontentContextProvider.make, {
              children: JsxRuntime.jsx(Kontent$PrezzeeRibbon.Consumer.make, {
                    children: JsxRuntime.jsxs("div", {
                          children: [
                            blockTitle,
                            JsxRuntime.jsxs(JsxRuntime.Fragment, {
                                  children: [
                                    JsxRuntime.jsx(Fresnel.Media.make, {
                                          lessThan: "md",
                                          children: JsxRuntime.jsx(BoardOfDirectorsList$PrezzeeRibbon.make, {
                                                children: Belt_Array.mapWithIndex(boardOfDirectorsCards, (function (idx, card) {
                                                        var key = String(idx);
                                                        return JsxRuntime.jsx("div", {
                                                                    children: card,
                                                                    className: listItem
                                                                  }, key);
                                                      }))
                                              })
                                        }),
                                    JsxRuntime.jsx(Fresnel.Media.make, {
                                          greaterThanOrEqual: "md",
                                          children: JsxRuntime.jsx(BoardOfDirectorsCarousel$PrezzeeRibbon.make, {
                                                children: Belt_Array.mapWithIndex(boardOfDirectorsCards, (function (idx, card) {
                                                        var key = String(idx);
                                                        return JsxRuntime.jsx(React.SwiperSlide, {
                                                                    children: card
                                                                  }, key);
                                                      })),
                                                arrowStyles: arrowStyles
                                              })
                                        })
                                  ]
                                })
                          ],
                          className: outerContainer
                        })
                  }),
              itemId: smartLink.itemId,
              elementCodename: smartLink.codename
            });
}

var Link$1;

var BoardOfDirectorsCard;

var BoardOfDirectorsCarousel;

var BoardOfDirectorsList;

var SwiperSlide;

var KontentConsumer;

var make = BoardOfDirectorsSection;

export {
  Link$1 as Link,
  BoardOfDirectorsCard ,
  BoardOfDirectorsCarousel ,
  BoardOfDirectorsList ,
  SwiperSlide ,
  KontentConsumer ,
  Styles ,
  make ,
}
/* outerContainer Not a pure module */
