import { createDeliveryClient } from '../helpers';
import { IContentItem, Elements } from '@kentico/kontent-delivery';

export type ZagInfoModal = IContentItem<{
  content: Elements.RichTextElement;
  participating_brands_link: Elements.TextElement;
  cta_text: Elements.TextElement;
}>;

const zagInfoModal = async (language: string, isPreview: boolean) => {
  const client = createDeliveryClient(language, isPreview);
  const data = await (await client.item<ZagInfoModal>('zag_explanation_modal').toPromise()).data;

  return data;
};

export default zagInfoModal;
