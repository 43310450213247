// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cn from "rescript-classnames/src/Cn.bs.js";
import * as Util from "../../common/util.bs.js";
import * as BsCss from "../../bindings/BsCss.bs.js";
import * as Curry from "@rescript/std/lib/es6/curry.js";
import * as Js_dict from "@rescript/std/lib/es6/js_dict.js";
import * as Segment from "../../bindings/Segment.bs.js";
import * as CmsImage from "../CmsImage.bs.js";
import Link from "next/link";
import * as Caml_array from "@rescript/std/lib/es6/caml_array.js";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.bs.js";
import * as Tag$PrezzeeRibbon from "@prezzee/ribbon/src/components/common/Tag/Tag.bs.js";
import * as JsxRuntime from "react/jsx-runtime";

var tagline = Curry._1(BsCss.style, {
      hd: BsCss.marginBottom(BsCss.px(12)),
      tl: {
        hd: BsCss.marginLeft(BsCss.px(12)),
        tl: {
          hd: BsCss.marginRight(BsCss.px(12)),
          tl: {
            hd: BsCss.media("(min-width: 768px)", {
                  hd: BsCss.marginLeft(BsCss.px(16)),
                  tl: {
                    hd: BsCss.marginRight(BsCss.px(16)),
                    tl: /* [] */0
                  }
                }),
            tl: /* [] */0
          }
        }
      }
    });

var card = Curry._1(BsCss.style, {
      hd: BsCss.position("relative"),
      tl: {
        hd: BsCss.overflow("hidden"),
        tl: {
          hd: BsCss.borderRadius(BsCss.px(8)),
          tl: {
            hd: BsCss.boxShadow(Css_Legacy_Core.Shadow.box(BsCss.px(0), BsCss.px(10), BsCss.px(30), undefined, undefined, BsCss.rgba(209, 213, 223, BsCss.pct(50.0)))),
            tl: /* [] */0
          }
        }
      }
    });

var Styles = {
  tagline: tagline,
  card: card
};

function CarouselCard(props) {
  var card$1 = props.card;
  var hasPromos = card$1.promos.length !== 0;
  var skuData = Js_dict.get(Belt_Option.getWithDefault(props.skuDict, {}), card$1.cmsId);
  var imageUrl = skuData !== undefined && skuData.themes.length !== 0 ? Belt_Option.getWithDefault(Belt_Option.flatMap(Caml_array.get(skuData.themes, 0), (function (a) {
                return a.image.url;
              })), card$1.imgUrl) : card$1.imgUrl;
  var promoIcon_src = require("../../../public/images/icon_promo.svg");
  return JsxRuntime.jsxs("div", {
              children: [
                hasPromos ? JsxRuntime.jsx(CmsImage.make, {
                        src: promoIcon_src,
                        alt: "Promo icon",
                        width: 30,
                        height: 79,
                        className: "absolute top-0 left-0 z-10"
                      }) : null,
                JsxRuntime.jsx(Link, {
                      href: "/store/" + card$1.slug,
                      prefetch: false,
                      children: JsxRuntime.jsxs("a", {
                            children: [
                              JsxRuntime.jsx(CmsImage.make, {
                                    src: imageUrl,
                                    alt: card$1.name,
                                    width: 452,
                                    height: 280,
                                    className: "rounded-t-lg",
                                    sizes: "(max-width: 600px) 72vw, (max-width: 900px) 40vw, (max-width: 1200px) 28vw, (max-width: 1440px) 17vw, 246px",
                                    priority: props.preload
                                  }),
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx("h3", {
                                            children: card$1.isSwappable ? Util.ReactStuff.s(card$1.displayName) : Util.ReactStuff.s(card$1.name),
                                            className: "font-semibold sm:text-17px leading-5 px-3 md:px-4 mb-2 mt-3"
                                          }),
                                      card$1.tagline.length > 0 ? JsxRuntime.jsx(Tag$PrezzeeRibbon.make, {
                                              children: Util.ReactStuff.s(card$1.tagline),
                                              className: tagline
                                            }) : JsxRuntime.jsx("p", {
                                              children: Util.ReactStuff.s(card$1.priceRange),
                                              className: "text-mid-gray px-3 md:px-4 text-sm leading-4 md:pb-3"
                                            })
                                    ],
                                    className: "h-23"
                                  })
                            ],
                            title: card$1.name,
                            onClick: (function (param) {
                                Segment.productClicked(Object.assign(Segment.cardToProduct(card$1), {
                                          position: 0
                                        }));
                              })
                          })
                    })
              ],
              className: Cn.make([
                    card,
                    "carousel-card"
                  ])
            });
}

var Link$1;

var Img;

var Tag;

var SkuPresentation;

var make = CarouselCard;

export {
  Link$1 as Link,
  Img ,
  Tag ,
  SkuPresentation ,
  Styles ,
  make ,
}
/* tagline Not a pure module */
