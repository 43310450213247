import { useRef } from 'react';
import { css, cx } from '@emotion/css';
import { Personal } from '@prezzee/ribbon/src/theme/Theme__Color.bs';
import { mobile } from '@prezzee/ribbon/src/theme/Theme__Responsive.bs.js';
import { Maybe } from 'models/types';
import { FC, useEffect, useMemo, useState } from 'react';
import { t as InventoryData_t } from 'api/Data/InventoryData.gen';
import { Card } from 'models/Models.bs';
import { Card_t } from 'models/Models.gen';
import Box from '@prezzee/ribbon-ui/lib/components/Box';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation, Scrollbar, Virtual } from 'swiper/modules';

import { make as RibbonNavgationArrows } from './RibbonNavgationArrows.bs';
import useSegment from 'hooks/useSegment';
import { userType } from 'bindings/Segment.bs';
import dynamic from 'next/dynamic';
import { useConfig } from 'contexts/ConfigProvider';
import { make as CardZoomAnimation } from 'components/CardZoomAnimation.bs';
import { make as CarouselCard } from './CarouselCard.bs';

const style = {
  arrows: css`
    position: absolute;
    top: -36px;
    right: 0;
    z-index: 10;
  `,
  swiperOverlay: css`
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 3;
    width: 80px;
    background-color: #${Personal.charcoal.VAL};
    background: linear-gradient(-90deg, #fff 0%, transparent 100%);
    height: 95%;
    @media ${mobile} {
      display: none;
    }
  `,
  swiperOverlayHidden: css`
    display: none;
  `,
  swiper: css`
    /* styles to display slides before swiper initialized to prevent layout shift */
    .swiper-slide {
      width: 83.256%;
      margin-right: 2px;
      height: auto;

      @media (min-width: 600px) {
        width: 43.024%;
      }

      @media (min-width: 900px) {
        width: 28.568%;
      }

      @media (min-width: 1200px) {
        width: 18.913%;
        margin-right: 5px;
      }
    }
  `,
};

// @ts-ignore
const RemoteSkuTile = dynamic(() => import('consumer_woc/components/SkuTile'), {
  loading: () => <span></span>,
  ssr: false,
});

const SkuTileContainer = dynamic(
  () =>
    // @ts-ignore using HeroBannerContainer from consumer_woc to provide theme to SkuTile as it does the stuff we want. TODO: Fix this, consumer-ui to be it's own MFE
    import('consumer_woc/components/HeroBanner').then(m => m.HeroBannerContainer),
  { ssr: false }
);

type Props = {
  className: string;
  inventory: Maybe<InventoryData_t>;
  skuDict: Maybe<any>;
  skuList: Maybe<string[]>;
  uid: Maybe<string>;
  cardsList: Maybe<any>;
};

let getUniqueClass = (classname: string, uid: string) => (uid !== '' ? `${classname}-${uid}` : classname);

const getCardPosition = (card: Card_t) => {
  const featuredCategory = card.categories.find(card => card.slug === 'featured');
  return featuredCategory ? featuredCategory.skuPosition : 0;
};

const SkuCarousel: FC<Props> = ({
  className,
  inventory = { skus: [] },
  skuDict,
  skuList = [],
  uid = '',
  cardsList = [],
}) => {
  const isLastSlide = useRef(false);
  const config = useConfig();
  const [isDesktopOrTablet, setIsDesktopOrTablet] = useState(true);
  const [isClient, setIsClient] = useState(false);
  const { customElementInteraction } = useSegment();

  useEffect(() => {
    setIsClient(typeof window !== 'undefined');
  }, []);

  useEffect(() => {
    const handleWindowResize = () => {
      setIsDesktopOrTablet(window.innerWidth >= 768);
    };
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const currentCards = useMemo(() => {
    const cards = inventory.skus.length ? inventory.skus.map(sku => Card.skuToCard(sku)) : cardsList;
    const pinnedCards =
      skuList.length !== 0
        ? cards
            .filter(card => skuList.includes(card.slug))
            .sort((a, b) => skuList.indexOf(a.slug) - skuList.indexOf(b.slug))
        : [];
    const list =
      pinnedCards.length !== 0
        ? pinnedCards
        : cards
            .filter(card => card.categories.find(category => category.name === 'Featured'))
            .sort((a, b) => getCardPosition(a) - getCardPosition(b));

    return list;
  }, [inventory.skus, skuList, cardsList]);

  const onSlideChange = swiper => {
    isLastSlide.current = swiper.isEnd;
  };

  return (
    // @ts-ignore
    <SkuTileContainer>
      <Box position="relative" marginTop="-36px" paddingTop="36px">
        <Swiper
          // Ignoring due to these issue with type exports
          //  - https://github.com/nolimits4web/swiper/issues/6843 & https://github.com/nolimits4web/swiper/issues/6885
          // @ts-ignore
          modules={[Pagination, Navigation, Scrollbar]}
          scrollbar={{ hide: isDesktopOrTablet }}
          onSlideChange={onSlideChange}
          spaceBetween={50}
          slidesPerView={7}
          className={cx(style.swiper, className)}
          breakpoints={{
            0: { slidesPerView: 1.2, spaceBetween: 2, slidesPerGroup: 1 },
            600: { slidesPerView: 2.2, spaceBetween: 2, slidesPerGroup: 2 },
            900: { slidesPerView: 3.2, spaceBetween: 2, slidesPerGroup: 3 },
            1200: { slidesPerView: 5.2, spaceBetween: 5, slidesPerGroup: 5 },
          }}
          grabCursor={true}
          freeMode={true}
          navigation={{
            nextEl: getUniqueClass('.navigation-next', uid),
            prevEl: getUniqueClass('.navigation-prev', uid),
            disabledClass: 'btn-disabled',
          }}
          onSlideNextTransitionStart={() => {
            customElementInteraction({
              ...userType,
              element: 'list_scroll_forward',
              journey: 'sku_list',
            });
          }}
          onSlidePrevTransitionStart={() => {
            customElementInteraction({
              ...userType,
              element: 'list_scroll_backward',
              journey: 'sku_list',
            });
          }}
        >
          {currentCards.map((card, index) => {
            const href = `/store/${card.slug}/`;
            const imgSrc = card.themes?.[0]?.image;
            const hasPromos = card.promos?.some(promo => promo.enabled);
            const tags = card.tagline ? [card.tagline] : [];
            const title = card.displayName;
            let banner;

            return (
              <SwiperSlide key={card.uid}>
                {config?.featureLayerBuyingWeb ? (
                  <Box transition="transform 0.3s ease" _hover={{ transform: 'scale(1.05)' }} mx={2} height="100%">
                    <RemoteSkuTile
                      // @ts-ignore
                      title={title}
                      imgSrc={imgSrc}
                      imgAlt={title}
                      href={href}
                      ribbon={hasPromos}
                      tags={tags}
                      height="100%"
                      banner={banner}
                    />
                  </Box>
                ) : (
                  <CardZoomAnimation>
                    <CarouselCard card={card} skuDict={skuDict} preload={isClient} />
                  </CardZoomAnimation>
                )}
              </SwiperSlide>
            );
          })}
        </Swiper>
        <div
          className={cx(
            style.swiperOverlay,
            'sku-swiper-overlay',
            isLastSlide.current ? style.swiperOverlayHidden : ''
          )}
        />
        <RibbonNavgationArrows
          className={cx('mb-30px invisible md:visible', style.arrows)}
          prevArrowClass={getUniqueClass('navigation-prev', uid)}
          nextArrowClass={getUniqueClass('navigation-next', uid)}
        />
      </Box>
    </SkuTileContainer>
  );
};

export default SkuCarousel;
