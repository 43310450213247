// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cn from "rescript-classnames/src/Cn.bs.js";
import * as BsCss from "../../../bindings/BsCss.bs.js";
import * as Curry from "@rescript/std/lib/es6/curry.js";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Text$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/Text/Text.bs.js";
import * as Vimeo$PrezzeeRibbon from "@prezzee/ribbon/src/bindings/Vimeo.bs.js";
import * as Kontent$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/Kontent/Kontent.bs.js";
import * as KontentContextProvider from "../index/KontentContextProvider.bs.js";
import * as RichText$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/RichText/RichText.bs.js";
import * as Accordion$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/Accordion/Accordion.bs.js";
import * as Theme__Color$PrezzeeRibbon from "@prezzee/ribbon/src/theme/Theme__Color.bs.js";
import * as Accordion__Panel$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/Accordion/Accordion__Panel.bs.js";
import * as Theme__Responsive$PrezzeeRibbon from "@prezzee/ribbon/src/theme/Theme__Responsive.bs.js";
import * as Theme__Typography$PrezzeeRibbon from "@prezzee/ribbon/src/theme/Theme__Typography.bs.js";
import * as Accordion__Heading$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/Accordion/Accordion__Heading.bs.js";
import * as Accordion__Trigger$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/Accordion/Accordion__Trigger.bs.js";
import * as VimeoPlayer__Video$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/VimeoPlayer/VimeoPlayer__Video.bs.js";
import * as VimeoPlayer__Player$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/VimeoPlayer/VimeoPlayer__Player.bs.js";

var wrapper = Curry._1(BsCss.style, {
      hd: BsCss.paddingTop(BsCss.px(100)),
      tl: {
        hd: BsCss.paddingBottom(BsCss.px(100)),
        tl: {
          hd: BsCss.backgroundColor(Theme__Color$PrezzeeRibbon.Business.stone),
          tl: {
            hd: BsCss.media(Theme__Responsive$PrezzeeRibbon.mobile, {
                  hd: BsCss.paddingTop(BsCss.px(50)),
                  tl: {
                    hd: BsCss.paddingBottom(BsCss.px(50)),
                    tl: /* [] */0
                  }
                }),
            tl: /* [] */0
          }
        }
      }
    });

var container = Curry._1(BsCss.style, {
      hd: BsCss.maxWidth(BsCss.px(650)),
      tl: {
        hd: BsCss.padding2(BsCss.zero, BsCss.px(20)),
        tl: {
          hd: BsCss.margin2(BsCss.zero, "auto"),
          tl: /* [] */0
        }
      }
    });

var blockTitle = Curry._1(BsCss.merge, {
      hd: Theme__Typography$PrezzeeRibbon.Styles.style1,
      tl: {
        hd: Curry._1(BsCss.style, {
              hd: BsCss.color(Theme__Color$PrezzeeRibbon.Business.charcoal),
              tl: {
                hd: BsCss.media(Theme__Responsive$PrezzeeRibbon.mobile, {
                      hd: BsCss.important(BsCss.fontSize(BsCss.px(24))),
                      tl: {
                        hd: BsCss.important(BsCss.lineHeight(BsCss.px(40))),
                        tl: /* [] */0
                      }
                    }),
                tl: {
                  hd: BsCss.selector("strong", {
                        hd: BsCss.color(Theme__Color$PrezzeeRibbon.Business.prezzeePurple),
                        tl: /* [] */0
                      }),
                  tl: /* [] */0
                }
              }
            }),
        tl: /* [] */0
      }
    });

var section = Curry._1(BsCss.style, {
      hd: BsCss.paddingTop(BsCss.px(50)),
      tl: {
        hd: BsCss.paddingBottom(BsCss.px(50)),
        tl: {
          hd: BsCss.media(Theme__Responsive$PrezzeeRibbon.mobile, {
                hd: BsCss.paddingTop(BsCss.px(20)),
                tl: {
                  hd: BsCss.paddingBottom(BsCss.px(20)),
                  tl: /* [] */0
                }
              }),
          tl: {
            hd: BsCss.lastChild({
                  hd: BsCss.paddingBottom(BsCss.zero),
                  tl: /* [] */0
                }),
            tl: /* [] */0
          }
        }
      }
    });

var sectionTitle = Curry._1(BsCss.merge, {
      hd: Theme__Typography$PrezzeeRibbon.Styles.style2,
      tl: {
        hd: Curry._1(BsCss.style, {
              hd: BsCss.color(Theme__Color$PrezzeeRibbon.Business.charcoal),
              tl: {
                hd: BsCss.important(BsCss.marginBottom(BsCss.px(20))),
                tl: {
                  hd: BsCss.media(Theme__Responsive$PrezzeeRibbon.mobile, {
                        hd: BsCss.important(BsCss.fontSize(BsCss.px(20))),
                        tl: {
                          hd: BsCss.important(BsCss.lineHeight(BsCss.px(20))),
                          tl: /* [] */0
                        }
                      }),
                  tl: /* [] */0
                }
              }
            }),
        tl: /* [] */0
      }
    });

var accordion = Curry._1(BsCss.style, {
      hd: BsCss.marginBottom(BsCss.px(20)),
      tl: {
        hd: BsCss.lastChild({
              hd: BsCss.marginBottom(BsCss.zero),
              tl: /* [] */0
            }),
        tl: /* [] */0
      }
    });

var accordionTitle = Curry._1(BsCss.style, {
      hd: BsCss.color(Theme__Color$PrezzeeRibbon.Business.charcoal),
      tl: /* [] */0
    });

var videoContainer = Curry._1(BsCss.style, {
      hd: BsCss.margin2(BsCss.rem(1), "zero"),
      tl: {
        hd: BsCss.position("relative"),
        tl: {
          hd: BsCss.paddingTop(BsCss.pct(360 / 510 * 100)),
          tl: {
            hd: BsCss.selector("iframe", {
                  hd: BsCss.position("absolute"),
                  tl: {
                    hd: BsCss.left("zero"),
                    tl: {
                      hd: BsCss.top("zero"),
                      tl: {
                        hd: BsCss.width(BsCss.pct(100)),
                        tl: {
                          hd: BsCss.height(BsCss.pct(100)),
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }),
            tl: /* [] */0
          }
        }
      }
    });

var richText = Curry._1(BsCss.style, {
      hd: BsCss.selector("a", {
            hd: BsCss.color({
                  NAME: "hex",
                  VAL: "1E54B7"
                }),
            tl: {
              hd: BsCss.textDecoration("underline"),
              tl: /* [] */0
            }
          }),
      tl: {
        hd: BsCss.selector("ul", {
              hd: BsCss.listStyleType("disc"),
              tl: {
                hd: BsCss.paddingLeft(BsCss.px(28)),
                tl: /* [] */0
              }
            }),
        tl: {
          hd: BsCss.selector("ol", {
                hd: BsCss.listStyleType("decimal"),
                tl: {
                  hd: BsCss.paddingLeft(BsCss.px(28)),
                  tl: /* [] */0
                }
              }),
          tl: {
            hd: BsCss.selector("p + ul, p + ol", {
                  hd: BsCss.marginTop(BsCss.px(12)),
                  tl: /* [] */0
                }),
            tl: /* [] */0
          }
        }
      }
    });

var additionalContent = Curry._1(BsCss.style, {
      hd: BsCss.marginTop(BsCss.px(32)),
      tl: {
        hd: BsCss.media(Theme__Responsive$PrezzeeRibbon.mobile, {
              hd: BsCss.marginTop(BsCss.px(16)),
              tl: /* [] */0
            }),
        tl: /* [] */0
      }
    });

var Styles = {
  wrapper: wrapper,
  container: container,
  blockTitle: blockTitle,
  section: section,
  sectionTitle: sectionTitle,
  accordion: accordion,
  accordionTitle: accordionTitle,
  videoContainer: videoContainer,
  richText: richText,
  additionalContent: additionalContent
};

function FAQSection(props) {
  var block = props.block;
  var blockSmartLink = block.smart_link;
  var additionalContent$1 = block.additionalContent;
  var playerOptions = Vimeo$PrezzeeRibbon.Player.options(undefined, false, undefined, undefined, undefined, true, undefined, false, false, undefined, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined);
  var sections = Belt_Array.mapWithIndex(block.faq_sections, (function (index, section$1) {
          var sectionSmartLink = section$1.smart_link;
          var sectionTitle$1 = section$1.title;
          var key = index.toString();
          var items = Belt_Array.mapWithIndex(section$1.faq_items, (function (idx, item) {
                  var itemSmartLink = item.smart_link;
                  var body_content = item.body_content;
                  var itemKey = idx.toString();
                  var bodyContent = body_content.length !== 0 ? Belt_Array.mapWithIndex(body_content, (function (bcIdx, bc) {
                            var bcKey = bcIdx.toString();
                            return JsxRuntime.jsx("div", {
                                        children: bc.NAME === "RichText" ? JsxRuntime.jsx(RichText$PrezzeeRibbon.make, {
                                                children: bc.VAL,
                                                className: richText
                                              }) : JsxRuntime.jsx("div", {
                                                children: JsxRuntime.jsx(VimeoPlayer__Video$PrezzeeRibbon.make, {
                                                      videoUrl: bc.VAL,
                                                      children: JsxRuntime.jsx(VimeoPlayer__Player$PrezzeeRibbon.make, {
                                                            id: bcKey + "--video",
                                                            playerOptions: playerOptions
                                                          })
                                                    }),
                                                className: videoContainer
                                              })
                                      }, bcKey);
                          })) : JsxRuntime.jsx(RichText$PrezzeeRibbon.make, {
                          children: item.body_text,
                          className: richText
                        });
                  return JsxRuntime.jsx(KontentContextProvider.make, {
                              children: JsxRuntime.jsx(Kontent$PrezzeeRibbon.Consumer.make, {
                                    children: JsxRuntime.jsx("div", {
                                          children: JsxRuntime.jsxs(Accordion$PrezzeeRibbon.make, {
                                                children: [
                                                  JsxRuntime.jsx(Accordion__Heading$PrezzeeRibbon.make, {
                                                        children: JsxRuntime.jsx(Accordion__Trigger$PrezzeeRibbon.make, {
                                                              children: JsxRuntime.jsx(Text$PrezzeeRibbon.make, {
                                                                    children: item.title
                                                                  })
                                                            }),
                                                        className: accordionTitle
                                                      }),
                                                  JsxRuntime.jsx(Accordion__Panel$PrezzeeRibbon.make, {
                                                        children: bodyContent
                                                      })
                                                ],
                                                colour: /* White */2,
                                                shadow: /* Normal */1
                                              }),
                                          className: accordion
                                        })
                                  }),
                              itemId: itemSmartLink.itemId,
                              elementCodename: itemSmartLink.codename
                            }, itemKey);
                }));
          return JsxRuntime.jsx(KontentContextProvider.make, {
                      children: JsxRuntime.jsx(Kontent$PrezzeeRibbon.Consumer.make, {
                            children: JsxRuntime.jsxs("div", {
                                  children: [
                                    sectionTitle$1 !== "" ? JsxRuntime.jsx("h2", {
                                            children: JsxRuntime.jsx(Text$PrezzeeRibbon.make, {
                                                  children: sectionTitle$1
                                                }),
                                            className: sectionTitle
                                          }) : null,
                                    items,
                                    JsxRuntime.jsx(RichText$PrezzeeRibbon.make, {
                                          children: additionalContent$1,
                                          className: Cn.make([
                                                richText,
                                                additionalContent
                                              ])
                                        })
                                  ],
                                  className: section
                                })
                          }),
                      itemId: sectionSmartLink.itemId,
                      elementCodename: sectionSmartLink.codename
                    }, key);
        }));
  return JsxRuntime.jsx(KontentContextProvider.make, {
              children: JsxRuntime.jsx(Kontent$PrezzeeRibbon.Consumer.make, {
                    children: JsxRuntime.jsx("div", {
                          children: JsxRuntime.jsxs("div", {
                                children: [
                                  JsxRuntime.jsx("div", {
                                        children: JsxRuntime.jsx(RichText$PrezzeeRibbon.make, {
                                              children: block.title
                                            }),
                                        className: blockTitle
                                      }),
                                  sections
                                ],
                                className: container
                              }),
                          className: wrapper
                        })
                  }),
              itemId: blockSmartLink.itemId,
              elementCodename: blockSmartLink.codename
            });
}

var KontentContextConsumer;

var make = FAQSection;

export {
  KontentContextConsumer ,
  Styles ,
  make ,
}
/* wrapper Not a pure module */
