import { FC } from 'react';
import { css, cx } from '@emotion/css';

import { make as RichText } from '@prezzee/ribbon/src/components/personal/RichText/RichText.gen';
import { Component } from '@prezzee/ribbon/src/components/common/SectionHeading/SectionHeading.bs';
import { Theme as SectionHeadingTheme } from '@prezzee/ribbon/src/components/business/SectionHeading/SectionHeading__Theme__Business.bs';
import { t as theme } from '@prezzee/ribbon/src/components/common/IconCard/IconCard__Theme.bs';
import { desktop, tablet, tabletSm } from '@prezzee/ribbon/src/theme/Theme__Responsive.bs';
import { Business } from '@prezzee/ribbon/src/theme/Theme__Color.bs';
import { make as CardGrid } from '@prezzee/ribbon/src/components/common/IconCard/IconCard__Grid.gen';
import { t as IconCardBlock_t } from 'api/graphql/IconCardBlock/Gql__IconCardBlock__Decoder__Block.gen';
import { section } from '../pages/index/IndexStyles.bs';
import { make as CardBuilder } from './CardBuilder.bs';
import useSmartLink from 'hooks/useSmartLink';
import { EMPTY_STRING } from 'common/utils/string';
import { htmlTag } from 'utils/Regex';
import { Color } from 'css/theme';
import { make as Description } from '@prezzee/ribbon/src/components/business/FeatureBlock/FeatureBlock__Description.gen';

const SectionHeading: FC<{ className?: string }> = Component(SectionHeadingTheme).make;

const classes = {
  wrapper: (bgColor: string) => css`
    background-color: ${bgColor === EMPTY_STRING ? EMPTY_STRING : bgColor};
  `,
  section: css`
    padding-top: 56px;
    padding-bottom: 42px;

    @media ${desktop} {
      padding-top: 82px;
      padding-bottom: 82px;
    }

    @media ${tablet} {
      padding-top: 56px;
      padding-bottom: 41px;
    }
  `,
  heading: isDescription => css`
    h1,
    h2,
    h3,
    h4 {
      font-weight: 600;
      text-align: center;
      color: #${Business.secondary.VAL};
      margin-top: 0;
      ${isDescription
        ? `
        margin-bottom: 17px;

        @media ${tablet} {
          margin-bottom: 24px;
        }
        `
        : `
        margin-bottom: 17px;

        @media ${desktop} {
          margin-bottom: 67px;
        }

        @media ${tablet} {
          margin-bottom: 24px;
        }
      `}
    }
    h1 {
      font-size: 50px;
      line-height: 63px;

      @media ${tabletSm} {
        font-size: 50px;
        line-height: 40px;
      }
    }

    h2 {
      font-size: 40px;
      line-height: 50px;

      @media ${tabletSm} {
        font-size: 32px;
        line-height: 40px;
      }
    }

    h3 {
      font-size: 36px;
      line-height: 46px;

      @media ${tabletSm} {
        font-size: 32px;
        line-height: 42px;
      }
    }

    h4 {
      font-size: 32px;
      line-height: 42px;

      @media ${tabletSm} {
        font-size: 28px;
        line-height: 38px;
      }
    }
  `,
  description: css`
    width: 100% !important;
    * {
      text-align: center;
      color: ${Color.Gray600} !important;
      line-height: 40px;
      margin-top: 0;
      margin-bottom: 17px;
      font-weight: 300;

      @media ${tablet} {
        margin-bottom: 24px;
      }
    }
  `,
};

type Props = {
  block: IconCardBlock_t;
};

const HowItWorksSection: FC<Props> = ({ block }) => {
  const { title, description, icon_cards, background_colour, smartLink } = block;
  const spotlightProps = useSmartLink(smartLink);
  const isTitle = title.replace(new RegExp(htmlTag, 'g'), '') !== '';
  const isDescription = description.replace(new RegExp(htmlTag, 'g'), '') !== '';

  return (
    <div className={classes.wrapper(background_colour)} {...spotlightProps}>
      <div className={cx([section, classes.section])}>
        {isTitle ? (
          // @ts-ignore
          <SectionHeading className={classes.heading(isDescription)}>
            <RichText>{title}</RichText>
          </SectionHeading>
        ) : null}
        {isDescription ? (
          <Description className={classes.description}>
            <RichText>{description}</RichText>
          </Description>
        ) : null}
        <CardGrid amountOfTiles={icon_cards.length}>
          {/* Call function theme is equal Light theme */}
          <CardBuilder iconCards={icon_cards} themeType={theme()} />
        </CardGrid>
      </div>
    </div>
  );
};

export default HowItWorksSection;
