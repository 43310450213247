// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cn from "rescript-classnames/src/Cn.bs.js";
import * as Button from "./Button.bs.js";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ReactFontawesome from "@fortawesome/react-fontawesome";

function RibbonNavgationArrows(props) {
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsxs("div", {
                    children: [
                      JsxRuntime.jsx("button", {
                            children: JsxRuntime.jsx(ReactFontawesome.FontAwesomeIcon, {
                                  icon: [
                                    "fas",
                                    "chevron-left"
                                  ],
                                  className: ""
                                }),
                            className: Cn.make([
                                  Button.redCircleStyle,
                                  props.prevArrowClass,
                                  "h-8 w-8 text-white flex items-center justify-center mr-3 transition-all duration-300 ease-in-out hover:shadow-md shadow-xl"
                                ]),
                            onClick: (function (e) {
                                e.preventDefault();
                              })
                          }),
                      JsxRuntime.jsx("button", {
                            children: JsxRuntime.jsx(ReactFontawesome.FontAwesomeIcon, {
                                  icon: [
                                    "fas",
                                    "chevron-right"
                                  ],
                                  className: ""
                                }),
                            className: Cn.make([
                                  Button.redCircleStyle,
                                  props.nextArrowClass,
                                  "h-8 w-8 text-white flex items-center justify-center ml-3 transition-all duration-300 ease-in-out hover:shadow-md shadow-xl"
                                ]),
                            onClick: (function (e) {
                                e.preventDefault();
                              })
                          })
                    ],
                    className: "flex"
                  }),
              className: Belt_Option.getWithDefault(props.className, "absolute top-0 left-0 ml-10 z-10 pl-10")
            });
}

var make = RibbonNavgationArrows;

export {
  make ,
}
/* Button Not a pure module */
