// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cn from "rescript-classnames/src/Cn.bs.js";
import * as Css from "bs-css-emotion/src/Css.bs.js";
import * as BsCss from "../../../bindings/BsCss.bs.js";
import * as Curry from "@rescript/std/lib/es6/curry.js";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";
import * as IndexStyles from "../index/IndexStyles.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ImageCollectionUtil from "./ImageCollectionUtil.bs.js";
import * as Kontent$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/Kontent/Kontent.bs.js";
import * as KontentContextProvider from "../index/KontentContextProvider.bs.js";
import * as RichText$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/RichText/RichText.bs.js";
import * as ImageGrid$PrezzeeRibbon from "@prezzee/ribbon/src/components/common/ImageGrid/ImageGrid.bs.js";
import * as OffsetBlocks$PrezzeeRibbon from "@prezzee/ribbon/src/components/common/OffsetBlocks/OffsetBlocks.bs.js";
import * as Theme__Color$PrezzeeRibbon from "@prezzee/ribbon/src/theme/Theme__Color.bs.js";
import * as Theme__Responsive$PrezzeeRibbon from "@prezzee/ribbon/src/theme/Theme__Responsive.bs.js";
import * as OffsetBlocks__Block$PrezzeeRibbon from "@prezzee/ribbon/src/components/common/OffsetBlocks/OffsetBlocks__Block.bs.js";
import * as SectionHeading__Business$PrezzeeRibbon from "@prezzee/ribbon/src/components/business/SectionHeading/SectionHeading__Business.bs.js";
import * as OffsetBlocks__BlockContent$PrezzeeRibbon from "@prezzee/ribbon/src/components/common/OffsetBlocks/OffsetBlocks__BlockContent.bs.js";
import * as OffsetBlocks__BlockHeading$PrezzeeRibbon from "@prezzee/ribbon/src/components/common/OffsetBlocks/OffsetBlocks__BlockHeading.bs.js";

var wrapper = Curry._1(BsCss.style, {
      hd: BsCss.paddingTop(BsCss.px(28)),
      tl: {
        hd: BsCss.paddingBottom(BsCss.px(60)),
        tl: {
          hd: BsCss.media(Theme__Responsive$PrezzeeRibbon.tabletSmUp, {
                hd: BsCss.paddingTop(BsCss.px(38)),
                tl: {
                  hd: BsCss.paddingBottom(BsCss.px(23)),
                  tl: /* [] */0
                }
              }),
          tl: {
            hd: BsCss.media(Theme__Responsive$PrezzeeRibbon.desktop, {
                  hd: BsCss.paddingTop(BsCss.px(65)),
                  tl: {
                    hd: BsCss.paddingBottom(BsCss.px(108)),
                    tl: /* [] */0
                  }
                }),
            tl: /* [] */0
          }
        }
      }
    });

var block = Curry._1(BsCss.style, {
      hd: BsCss.important(BsCss.flexDirection("row")),
      tl: /* [] */0
    });

var kontentWrapper = Curry._1(BsCss.style, {
      hd: BsCss.display("flex"),
      tl: {
        hd: BsCss.width(BsCss.pct(100)),
        tl: {
          hd: BsCss.flexDirection(BsCss.column),
          tl: /* [] */0
        }
      }
    });

var heading = Curry._1(BsCss.style, {
      hd: BsCss.selector("h1, h2, h3, h4, h5, h6", {
            hd: BsCss.margin3("zero", "zero", BsCss.px(24)),
            tl: {
              hd: BsCss.textAlign("center"),
              tl: {
                hd: BsCss.color(Theme__Color$PrezzeeRibbon.Business.charcoal),
                tl: {
                  hd: BsCss.fontSize(BsCss.px(32)),
                  tl: {
                    hd: BsCss.lineHeight(BsCss.px(40)),
                    tl: {
                      hd: BsCss.media(Theme__Responsive$PrezzeeRibbon.desktop, {
                            hd: BsCss.fontSize(BsCss.px(42)),
                            tl: {
                              hd: BsCss.lineHeight(BsCss.px(58)),
                              tl: {
                                hd: BsCss.marginBottom(BsCss.px(38)),
                                tl: /* [] */0
                              }
                            }
                          }),
                      tl: /* [] */0
                    }
                  }
                }
              }
            }
          }),
      tl: /* [] */0
    });

var container = Curry._1(BsCss.style, {
      hd: BsCss.maxWidth(BsCss.px(768)),
      tl: {
        hd: BsCss.marginLeft("auto"),
        tl: {
          hd: BsCss.marginRight("auto"),
          tl: /* [] */0
        }
      }
    });

var reviews = Curry._1(BsCss.style, {
      hd: BsCss.marginTop(BsCss.px(24)),
      tl: {
        hd: BsCss.marginBottom(BsCss.px(7)),
        tl: {
          hd: BsCss.media(Theme__Responsive$PrezzeeRibbon.desktop, {
                hd: BsCss.marginTop(BsCss.px(52)),
                tl: /* [] */0
              }),
          tl: /* [] */0
        }
      }
    });

var Styles = {
  wrapper: wrapper,
  block: block,
  kontentWrapper: kontentWrapper,
  heading: heading,
  container: container,
  reviews: reviews
};

function getHeading(str) {
  return JsxRuntime.jsx(SectionHeading__Business$PrezzeeRibbon.make, {
              children: JsxRuntime.jsx(RichText$PrezzeeRibbon.make, {
                    children: str
                  }),
              className: heading
            });
}

function getTiles(tiles) {
  var blocks = Belt_Array.mapWithIndex(tiles, (function (idx, tile) {
          var key = String(idx);
          var smartLink = tile.smartLink;
          return JsxRuntime.jsx(OffsetBlocks__Block$PrezzeeRibbon.make, {
                      children: JsxRuntime.jsx(KontentContextProvider.make, {
                            children: JsxRuntime.jsx(Kontent$PrezzeeRibbon.Consumer.make, {
                                  children: JsxRuntime.jsxs("div", {
                                        children: [
                                          JsxRuntime.jsx(OffsetBlocks__BlockHeading$PrezzeeRibbon.make, {
                                                children: JsxRuntime.jsx(RichText$PrezzeeRibbon.make, {
                                                      children: tile.title
                                                    })
                                              }),
                                          JsxRuntime.jsx(OffsetBlocks__BlockContent$PrezzeeRibbon.make, {
                                                children: JsxRuntime.jsx(ImageGrid$PrezzeeRibbon.make, {
                                                      children: ImageCollectionUtil.Helpers.getImages(tile.images),
                                                      className: idx === 1 ? reviews : "",
                                                      gap: tile.gap_desktop,
                                                      gapTablet: tile.gap_tablet,
                                                      gapMobile: tile.gap_mobile,
                                                      perRow: tile.display_per_row_desktop,
                                                      perRowTablet: tile.display_per_row_tablet,
                                                      perRowMobile: tile.display_per_row_mobile
                                                    })
                                              })
                                        ],
                                        className: kontentWrapper
                                      })
                                }),
                            itemId: smartLink.itemId,
                            elementCodename: smartLink.codename
                          }),
                      className: block
                    }, key);
        }));
  return JsxRuntime.jsx(OffsetBlocks$PrezzeeRibbon.make, {
              children: blocks
            });
}

var Build = {
  getHeading: getHeading,
  getTiles: getTiles
};

function WeveGotALotToBeProudAbout(props) {
  var block = props.block;
  var smartLink = block.smartLink;
  var tiles = block.tiles;
  var title = block.title;
  var heading = title === "" ? null : getHeading(title);
  var bgColourStyle = Belt_Option.mapWithDefault(block.background_colour, "", (function (bg) {
          return Curry._1(Css.style, {
                      hd: Css.unsafe("backgroundColor", bg),
                      tl: /* [] */0
                    });
        }));
  var offsetTiles = tiles.length !== 0 ? getTiles(tiles) : null;
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsx("div", {
                    children: JsxRuntime.jsx(KontentContextProvider.make, {
                          children: JsxRuntime.jsx(Kontent$PrezzeeRibbon.Consumer.make, {
                                children: JsxRuntime.jsxs("div", {
                                      children: [
                                        heading,
                                        offsetTiles
                                      ],
                                      className: container
                                    })
                              }),
                          itemId: smartLink.itemId,
                          elementCodename: smartLink.codename
                        }),
                    className: IndexStyles.section
                  }),
              className: Cn.make([
                    wrapper,
                    bgColourStyle
                  ])
            });
}

var SectionHeading;

var OffsetBlocks;

var Block;

var Heading;

var Content;

var RichText;

var ImageGrid;

var KontentContextConsumer;

var ImageCollectionHelpers;

var make = WeveGotALotToBeProudAbout;

export {
  SectionHeading ,
  OffsetBlocks ,
  Block ,
  Heading ,
  Content ,
  RichText ,
  ImageGrid ,
  KontentContextConsumer ,
  ImageCollectionHelpers ,
  Styles ,
  Build ,
  make ,
}
/* wrapper Not a pure module */
