// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.bs.js";
import * as BsCss from "../../../bindings/BsCss.bs.js";
import * as Curry from "@rescript/std/lib/es6/curry.js";
import * as CmsImage from "../../CmsImage.bs.js";
import Link from "next/link";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";
import * as IndexStyles from "../index/IndexStyles.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Button$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/Button/Button.bs.js";
import * as Fresnel$PrezzeeRibbon from "@prezzee/ribbon/src/bindings/Fresnel/Fresnel.bs.js";
import * as Kontent$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/Kontent/Kontent.bs.js";
import * as KontentContextProvider from "../index/KontentContextProvider.bs.js";
import * as RichText$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/RichText/RichText.bs.js";
import * as Theme__Color$PrezzeeRibbon from "@prezzee/ribbon/src/theme/Theme__Color.bs.js";
import * as Theme__Responsive$PrezzeeRibbon from "@prezzee/ribbon/src/theme/Theme__Responsive.bs.js";
import * as InnovativeWayToGift$PrezzeeRibbon from "@prezzee/ribbon/src/components/business/InnovativeWayToGift/InnovativeWayToGift.bs.js";
import * as InnovativeWayToGift__CTA$PrezzeeRibbon from "@prezzee/ribbon/src/components/business/InnovativeWayToGift/InnovativeWayToGift__CTA.bs.js";
import * as InnovativeWayToGift__Heading$PrezzeeRibbon from "@prezzee/ribbon/src/components/business/InnovativeWayToGift/InnovativeWayToGift__Heading.bs.js";
import * as InnovativeWayToGift__BodyText$PrezzeeRibbon from "@prezzee/ribbon/src/components/business/InnovativeWayToGift/InnovativeWayToGift__BodyText.bs.js";
import * as InnovativeWayToGift__TextContainer$PrezzeeRibbon from "@prezzee/ribbon/src/components/business/InnovativeWayToGift/InnovativeWayToGift__TextContainer.bs.js";
import * as InnovativeWayToGift__ImageContainer$PrezzeeRibbon from "@prezzee/ribbon/src/components/business/InnovativeWayToGift/InnovativeWayToGift__ImageContainer.bs.js";
import * as InnovativeWayToGift__BackgroundImage$PrezzeeRibbon from "@prezzee/ribbon/src/components/business/InnovativeWayToGift/InnovativeWayToGift__BackgroundImage.bs.js";

var button = Curry._1(BsCss.style, {
      hd: BsCss.marginLeft(BsCss.px(12)),
      tl: {
        hd: BsCss.marginTop(BsCss.px(12)),
        tl: /* [] */0
      }
    });

var backgroundImg = Curry._1(BsCss.style, {
      hd: BsCss.width(BsCss.pct(100)),
      tl: /* [] */0
    });

var cmsImg = Curry._1(BsCss.style, {
      hd: BsCss.media(Theme__Responsive$PrezzeeRibbon.smallScreenOrMobile, {
            hd: BsCss.maxWidth(BsCss.px(600)),
            tl: {
              hd: BsCss.marginRight("auto"),
              tl: {
                hd: BsCss.marginLeft("auto"),
                tl: /* [] */0
              }
            }
          }),
      tl: /* [] */0
    });

var Styles = {
  button: button,
  backgroundImg: backgroundImg,
  cmsImg: cmsImg
};

function InnovativeWayToGiftSection(props) {
  var block = props.block;
  var smartLink = block.smartLink;
  var defaultBackgroundColour = Curry._1(BsCss.style, {
        hd: BsCss.backgroundColor(Theme__Color$PrezzeeRibbon.Common.peach),
        tl: /* [] */0
      });
  var bgDesktop = Belt_Option.mapWithDefault(block.desktop_background, null, (function (param) {
          return JsxRuntime.jsx(CmsImage.make, {
                      src: param.url,
                      alt: param.description,
                      width: param.width,
                      height: param.height,
                      className: cmsImg,
                      sizes: "45vw"
                    });
        }));
  var bgTablet = Belt_Option.mapWithDefault(block.tablet_background, null, (function (param) {
          return JsxRuntime.jsx(CmsImage.make, {
                      src: param.url,
                      alt: param.description,
                      width: param.width,
                      height: param.height,
                      className: cmsImg,
                      sizes: "45vw"
                    });
        }));
  var bgMobile = Belt_Option.mapWithDefault(block.mobile_background, null, (function (param) {
          return JsxRuntime.jsx(CmsImage.make, {
                      src: param.url,
                      alt: param.description,
                      width: param.width,
                      height: param.height,
                      className: cmsImg,
                      sizes: "" + Theme__Responsive$PrezzeeRibbon.tabletSm + " 100vw, 600px"
                    });
        }));
  var bgColourStyle = Belt_Option.mapWithDefault(block.background_colour, defaultBackgroundColour, (function (bg) {
          return Curry._1(Css.style, {
                      hd: Css.unsafe("backgroundColor", bg),
                      tl: /* [] */0
                    });
        }));
  var bg = JsxRuntime.jsxs(JsxRuntime.Fragment, {
        children: [
          JsxRuntime.jsx(Fresnel$PrezzeeRibbon.Media.make, {
                greaterThanOrEqual: "rbDesktop",
                children: bgDesktop
              }),
          JsxRuntime.jsx(Fresnel$PrezzeeRibbon.Media.make, {
                at: "rbSmallScreen",
                children: bgTablet
              }),
          JsxRuntime.jsx(Fresnel$PrezzeeRibbon.Media.make, {
                lessThan: "rbSmallScreen",
                children: bgMobile
              })
        ]
      });
  var background = JsxRuntime.jsx(InnovativeWayToGift__BackgroundImage$PrezzeeRibbon.make, {
        children: bg,
        className: backgroundImg
      });
  var heading = JsxRuntime.jsx(InnovativeWayToGift__Heading$PrezzeeRibbon.make, {
        children: JsxRuntime.jsx(RichText$PrezzeeRibbon.make, {
              children: block.title
            })
      });
  var bodyText = JsxRuntime.jsx(InnovativeWayToGift__BodyText$PrezzeeRibbon.make, {
        children: JsxRuntime.jsx(RichText$PrezzeeRibbon.make, {
              children: block.description
            })
      });
  var ctaButtons = Belt_Array.mapWithIndex(block.buttons, (function (index, ctaButton) {
          var smartLink = ctaButton.smartLink;
          var target = ctaButton.open_in_new_window ? "_blank" : "";
          return JsxRuntime.jsx(Button$PrezzeeRibbon.make, {
                      children: JsxRuntime.jsx(Link, {
                            href: ctaButton.cta_url,
                            prefetch: false,
                            children: JsxRuntime.jsx("a", {
                                  children: JsxRuntime.jsx(KontentContextProvider.make, {
                                        children: JsxRuntime.jsx(Kontent$PrezzeeRibbon.Consumer.make, {
                                              children: JsxRuntime.jsx("button", {
                                                    children: ctaButton.cta_text
                                                  })
                                            }),
                                        itemId: smartLink.itemId,
                                        elementCodename: smartLink.codename
                                      }),
                                  target: target
                                })
                          }),
                      colour: /* BusinessPurple */3,
                      variant: /* Small */1,
                      className: button
                    }, index.toString());
        }));
  var ctaLine = JsxRuntime.jsx(InnovativeWayToGift__CTA$PrezzeeRibbon.make, {
        children: ctaButtons
      });
  return JsxRuntime.jsx(KontentContextProvider.make, {
              children: JsxRuntime.jsx(Kontent$PrezzeeRibbon.Consumer.make, {
                    children: JsxRuntime.jsx("div", {
                          children: JsxRuntime.jsxs(InnovativeWayToGift$PrezzeeRibbon.make, {
                                children: [
                                  JsxRuntime.jsx(InnovativeWayToGift__ImageContainer$PrezzeeRibbon.make, {
                                        children: background
                                      }),
                                  JsxRuntime.jsxs(InnovativeWayToGift__TextContainer$PrezzeeRibbon.make, {
                                        children: [
                                          heading,
                                          bodyText,
                                          ctaLine
                                        ]
                                      })
                                ],
                                classname: IndexStyles.section
                              }),
                          className: bgColourStyle
                        })
                  }),
              itemId: smartLink.itemId,
              elementCodename: smartLink.codename
            });
}

var InnovativeWayToGift;

var BackgroundImage;

var ImageContainer;

var TextContainer;

var Heading;

var BodyText;

var ButtonContainer;

var RichText;

var Link$1;

var KontentContextConsumer;

var make = InnovativeWayToGiftSection;

export {
  InnovativeWayToGift ,
  BackgroundImage ,
  ImageContainer ,
  TextContainer ,
  Heading ,
  BodyText ,
  ButtonContainer ,
  RichText ,
  Link$1 as Link,
  KontentContextConsumer ,
  Styles ,
  make ,
}
/* button Not a pure module */
