import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { FC } from 'react';
import { css, cx } from '@emotion/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';
import Head from 'next/head';

import colors from '@prezzee/ribbon-ui/lib/theme/foundations/colors';
import { belowLargeScreen, tabletOrMobile } from '@prezzee/ribbon/src/theme/Theme__Responsive.bs.js';

import useSmartLink from 'hooks/useSmartLink';
import N1ColumnBanner from './N1ColumnBanner';
import N2ColumnBanner from './N2ColumnBanner';
import { Nullable } from 'models/types';
import { fullWidthSection } from 'components/pages/index/IndexStyles.bs';
import { t as N1ColumnBanner_t } from 'api/graphql/HeroBanner/Gql__HeroBanner__N1ColumnBanner__Decoder.gen';
import { t as N2ColumnBanner_t } from 'api/graphql/HeroBanner/Gql__HeroBanner__N2ColumnBanner__Decoder.gen';
import { t as SmartLink_t } from 'components/library/SmartLink.gen';
import useDisclosure from '@prezzee/ribbon-ui/lib/hooks/useDisclosure';
import ZagExplanationModal from 'components/pages/store/zag/ZagExplanationModal';

const RemoteHeroBanner = dynamic(() => import('./RemoteHeroBanner'), {
  loading: () => <span></span>,
  ssr: false,
});

const style = {
  wrapper: css`
    width: 100%;
  `,
  pagination: css`
    display: flex;
    justify-content: center;
    padding: 11px 20px;
    height: 18px;
    box-sizing: content-box;

    .bullet {
      width: 18px;
      height: 18px;
      margin-right: 2px;
      cursor: pointer;
      position: relative;

      &:last-child {
        margin-right: 0;
      }

      &::after {
        content: '';
        display: block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: ${colors.purple[100]};
        position: absolute;
        top: 5px;
        left: 5px;
      }
    }

    .bullet_active {
      &::after {
        background-color: ${colors.purple[400]};
      }
    }
  `,
};

const styleGlobal = {
  overlayFullwidth: `
  .search-bar + .hero-banner {
    margin-top: -72px;
  }
`,
  overlayBoxed: `
  .search-bar > div {
    margin-left: 12px;
    margin-right: 12px;
  }
  .search-bar + .hero-banner {
    margin-top: -72px;
  }
`,
  onlyTablet: (tabletBreakpoint, styles) => `
  @media ${tabletBreakpoint} {
    ${styles}
  }
`,
};

type Props = {
  layerEnabled?: boolean;
  block: {
    smartLink: SmartLink_t;
    width: string;
    isSearchBarOverlay: boolean;
    isBorderRadius: boolean;
    n1ColumnBanners: N1ColumnBanner_t[];
    n2ColumnBanner: Nullable<N2ColumnBanner_t>;
  };
};

const HeroBanner: FC<Props> = ({
  layerEnabled,
  block,
  block: { smartLink, width, isSearchBarOverlay, n1ColumnBanners, n2ColumnBanner, isBorderRadius },
}) => {
  const isOneColumn = !!n1ColumnBanners.length;
  const isSlider = n1ColumnBanners.length > 1;
  const isFullWidth = width === 'full_width';
  const spotlightProps = useSmartLink(smartLink);
  const tabletBreakpoint = isFullWidth ? tabletOrMobile : belowLargeScreen;
  const overlayStyles = isFullWidth ? styleGlobal.overlayFullwidth : styleGlobal.overlayBoxed;
  const router = useRouter();
  const zagExplanationModal = useDisclosure();

  const onClickBannerCta = (btn: { cta_url: string }) => {
    switch (btn.cta_url) {
      case '#modal_prezzee-plus-explanation': {
        zagExplanationModal.onOpen();
        break;
      }
      default: {
        router.push(btn.cta_url);
      }
    }
  };

  return (
    <section className={cx(style.wrapper, !isFullWidth && fullWidthSection, 'hero-banner')} {...spotlightProps}>
      {isSearchBarOverlay && (
        <Head>
          <style>{isOneColumn ? overlayStyles : styleGlobal.onlyTablet(tabletBreakpoint, overlayStyles)}</style>
        </Head>
      )}
      {isOneColumn && (
        <>
          {isSlider ? (
            <Swiper
              // Ignoring due to these issue with type exports
              //  - https://github.com/nolimits4web/swiper/issues/6843 & https://github.com/nolimits4web/swiper/issues/6885
              // @ts-ignore
              slidesPerView={1}
              modules={[Pagination, Autoplay]}
              maxBackfaceHiddenSlides={0}
              // delay with 24h to prevent autoplay on mobile, setting autoplay to false disable enabling it on breakpoints
              autoplay={{ delay: 24 * 60 * 60 * 1000 }}
              pagination={{
                clickable: true,
                el: '.hero-banner__pagination',
                bulletClass: 'bullet',
                bulletActiveClass: 'bullet_active',
              }}
              autoHeight={true}
              breakpoints={{
                [isFullWidth ? 1024 : 1288]: {
                  autoplay: { delay: 10000, pauseOnMouseEnter: true, disableOnInteraction: false },
                },
              }}
            >
              {n1ColumnBanners.map((columnProps, index) => (
                <SwiperSlide key={columnProps.smartLink.codename}>
                  {layerEnabled ? (
                    <>
                      {/* @ts-ignore */}
                      <RemoteHeroBanner data={block} onClickBannerCta={onClickBannerCta} carouselIndex={index} />
                    </>
                  ) : (
                    <N1ColumnBanner isFullScreen={isFullWidth} isBorderRadius={isBorderRadius} {...columnProps} />
                  )}
                </SwiperSlide>
              ))}
            </Swiper>
          ) : layerEnabled ? (
            <>
              {/* @ts-ignore */}
              <RemoteHeroBanner data={block} onClickBannerCta={onClickBannerCta} />
            </>
          ) : (
            <N1ColumnBanner isFullScreen={isFullWidth} isBorderRadius={isBorderRadius} {...n1ColumnBanners[0]} />
          )}
          {isSlider ? <div className={cx('hero-banner__pagination', style.pagination)}></div> : null}
        </>
      )}
      {n2ColumnBanner &&
        (layerEnabled ? (
          <>
            {/* @ts-ignore */}
            <RemoteHeroBanner data={block} onClickBannerCta={onClickBannerCta} />
          </>
        ) : (
          <N2ColumnBanner isFullScreen={isFullWidth} isBorderRadius={isBorderRadius} {...n2ColumnBanner} />
        ))}
      {layerEnabled && zagExplanationModal.isOpen && <ZagExplanationModal onClose={zagExplanationModal.onClose} />}
    </section>
  );
};

export default HeroBanner;
